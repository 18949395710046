import React, { Component } from 'react';
import Moment from 'moment';
import { notification, DatePicker, Select } from 'antd';
import Pagination from 'react-js-pagination';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { listOrder } from './../../redux/order/action';

class Row extends Component {
	constructor(props) {
		super();
		this.state = {
			check: false,
			status: true,
		};
	}


	componentDidMount() {
		this.setState({
			check: false,
			status: this.props.obj.status,
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
	}

	handleCheckBox = e => {
		if (e.target.checked) {
			this.props.handleCheckedIds(this.props.obj._id, 'add');
			this.setState({
				check: e.target.checked
			});
		} else {
			this.props.handleCheckedIds(this.props.obj._id, 'remove');
			this.setState({
				check: e.target.checked
			});
		}
	};


	handleChangeStatus = async e => {

	};

	handleCheck = async (e) => {
	}

	render() {

		return (
			<tr className="v-middle" data-id={17}>
				<td>
					<label className="ui-check m-0">
						<input
							type="checkbox"
							className="checkInputItem"
							name="checkItem"
							value={this.props.obj._id}
							onChange={this.handleCheckBox}
						/>{' '}
						<i />
					</label>
				</td>
				<td className="text-left">
					<span className="item-amount d-none d-sm-block text-sm">
						{this.props.obj.updated_at &&
							Moment(this.props.obj.updated_at).format(
								'DD/MM/YYYY HH:mm:ss',
							)}
					</span>
				</td>
				<td className="flex">
					<Link
						className="item-author text-color"
						to={'/order/' + this.props.obj._id + '/edit'}>
						{this.props.obj.customer_name}
					</Link>
				</td>
				<td className="text-left">
					<span className="item-amount d-none d-sm-block text-sm">
						{this.props.obj.customer_phone}
					</span>
				</td>
				<td className="text-left">
					<span className="item-amount d-none d-sm-block text-sm">
						{this.props.obj.payment_method}
					</span>
				</td>
				<td className="text-left">
					<span className="item-amount d-none d-sm-block text-sm">
						{this.props.obj.total.toLocaleString("en-EN", {
							minimumFractionDigits: 0,
						})}
					</span>
				</td>
				<td className="text-left">
					<span className="item-amount d-none d-sm-block text-sm">
						{this.props.obj.discount_total ? this.props.obj.discount_total.toLocaleString("en-EN", {
							minimumFractionDigits: 0,
						}) : 0}
					</span>
				</td>
				<td className="text-left">
					<span className="item-amount d-none d-sm-block text-sm">
						{this.props.obj.status}
					</span>
				</td>
				<td>
					<div className="item-action dropdown">
						<a
							href="/"
							data-toggle="dropdown"
							className="text-muted">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={16}
								height={16}
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
								className="feather feather-more-vertical">
								<circle cx={12} cy={12} r={1} />
								<circle cx={12} cy={5} r={1} />
								<circle cx={12} cy={19} r={1} />
							</svg>
						</a>
						<div
							className="dropdown-menu dropdown-menu-right bg-white"
							role="menu">
							<Link
								className="dropdown-item"
								to={'/order/' + this.props.obj._id + '/details'}>
								Xem chi tiết
							</Link>
						</div>
					</div>
				</td>
			</tr>
		);
	}
}

class Order extends Component {
	constructor(props) {
		super();
		this.state = {
			keyword: "",
			data: [],
			limit: 20,
			ids: [],
			checkAll: false,
			from_date: '',
			to_date: '',
			student_id: '',
			status: '',
			payment_method: '',
		};
	}

	async componentDidMount() {
		await this.props.listOrder(this.getData());

		if (this.props.limit) {
			await this.setState({
				limit: this.props.limit,
				checkAll: false,
				ids: this.props.ids
			});
		}
	}

	fetchRows() {
		if (this.props.orders instanceof Array) {
			return this.props.orders.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						handleCheckedIds={this.handleCheckedIds}
						updateMagazine={this.props.updateMagazine}
						addDataRemoveMagazine={this.props.addDataRemoveMagazine}
						// getData={this.getData}
						check={this.props.check}
					// updateReview={this.props.updateReview}
					/>
				);
			});
		}
	}

	handleCheckedIds = async (id, type = '') => {
		var _ids = this.state.ids;
		if (type === 'add') {
			if (_ids.indexOf(id) < 0) {
				_ids.push(id);
			}
		}
		if (type === 'remove') {
			var index = _ids.indexOf(id);
			if (index > -1) {
				_ids.splice(index, 1);
			}
		}

		this.setState({
			ids: _ids
		})
	}

	onChange = (e) => {
		var name = e.target.name;
		var value = e.target.value;

		console.log(name, value);
		this.setState({
			[name]: value
		})
	};

	getData = (pageNumber = 1) => {
		const data = {
			page: pageNumber,
			limit: this.props.limit,
		};
		if (this.state.keyword !== null) {
			data['keyword'] = this.state.keyword;
		}
		if (this.state.from_date != null) {
			data['from_date'] = this.state.from_date;
		}
		if (this.state.to_date != null) {
			data['to_date'] = this.state.to_date;
		}
		if (this.state.status != null) {
			data['status'] = this.state.status;
		}
		if (this.state.payment_method != null) {
			data['payment_method'] = this.state.payment_method;
		}
		return data;
	};


	onSubmit = (e) => {
		e.preventDefault();
		this.props.listOrder(this.getData());
	};

	handleChangePage = async pageNumber => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		await this.props.listCredit(this.getData(pageNumber));
	};

	handleDelete = async () => {
	};

	handleChange = async e => {

	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.checkAll !== nextProps.check) {
			this.setState({
				checkAll: nextProps.check,
			});
		}
	}

	handleCheckAll = async (e) => {
		var inputs = document.querySelectorAll('.checkInputItem');
		var flag = false;

		if (e.target.checked) {
			flag = true;
		}

		var _ids = [];
		for (var i = 0; i < inputs.length; i++) {
			inputs[i].checked = flag;

			if (flag) {
				_ids.push(inputs[i].value);
			} else {
				_ids = [];
			}
		}

		this.setState({
			ids: _ids
		})

	};

	handleDeleteAll = async (e) => {

	}

	changeDateStart = async (date, dateString) => {
		if (date !== null) {
			await this.setState({
				from_date: date.format("YYYY/MM/DD") + ' ' + '00:00:00',
			});
		}
		this.props.listOrder(this.getData());
	};

	changeDateEnd = async (date, dateString) => {
		if (date !== null) {
			await this.setState({
				to_date: date.format("YYYY/MM/DD") + ' ' + '23:59:59',
			});
		}
		this.props.listOrder(this.getData());
	};

	onFilterOrder = async (event) => {
		const name = event.target.name;
		let value = event.target.value;
		await this.setState({
			[name]: value
		})
		this.props.listOrder(this.getData());
	}
	resetDataFilter = async () => {
		await this.setState({
			status: '',
			payment_method: '',
			to_date: '',
			from_date: ''
		})

		this.props.listOrder(this.getData());

	}

	render() {
		let displayFrom =
			this.props.page === 1
				? 1
				: (parseInt(this.props.page) - 1) * this.props.limit;
		let displayTo =
			this.props.page === 1
				? this.props.limit
				: displayFrom + this.props.limit;
		displayTo = displayTo > this.props.total ? this.props.total : displayTo;
		return (
			<div>
				<div className="page-hero page-container" id="page-hero">
					<div className="padding d-flex">
						<div className="page-title">
							<h2 className="text-md text-highlight">Đơn hàng</h2>
						</div>
						<div className="flex" />
						<div>
							<Link
								to={`magazine/create`}
								className="btn btn-sm btn-primary text-muted credit-btn-add">
								<span className="d-none d-sm-inline mx-1">
									Thêm mới
								</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width={16}
									height={16}
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth={2}
									strokeLinecap="round"
									strokeLinejoin="round"
									className="feather feather-arrow-right">
									<line x1={5} y1={12} x2={19} y2={12} />
									<polyline points="12 5 19 12 12 19" />
								</svg>
							</Link>
						</div>
					</div>
				</div>
				<div className="page-content page-container" id="page-content">
					<div className="padding">
						<div className="mb-5">
							<div className="toolbar">
								<div className="btn-group">
								</div>
								<form className="flex" onSubmit={this.onSubmit}>
									<div className="input-group fix">
										<input
											type="text"
											className="form-control form-control-theme keyword-custom"
											placeholder="Nhập từ khoá tìm kiếm..."
											onChange={this.onChange}
											name="keyword"
										/>{' '}
										<span className="input-group-append">
											<button
												className="btn btn-white btn-sm btn-search"
												type="submit">
												<span className="d-flex text-muted">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width={16}
														height={16}
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														strokeWidth={2}
														strokeLinecap="round"
														strokeLinejoin="round"
														className="feather feather-search">
														<circle
															cx={11}
															cy={11}
															r={8}
														/>
														<line
															x1={21}
															y1={21}
															x2="16.65"
															y2="16.65"
														/>
													</svg>
												</span>
											</button>
										</span>

										<div style={{ margin: "0px 10px" }}>
											<select onChange={this.onFilterOrder} name="status" className="custom-select"
												value={
													this.state.status
												}>
												<option value="">-- Trạng thái --</option>
												<option value="PENDING">Chờ xử lý</option>
												<option value="PROCESSING">Đang xử lý</option>
												<option value="PAID">Đã thanh toán</option>
												<option value="SHIPPING">Đang giao hàng</option>
												<option value="SUCCESS">Thành công</option>
											</select>
										</div>

										<div>
											<select onChange={this.onFilterOrder} name="payment_method" className="custom-select"
												value={
													this.state.payment_method
												}>
												<option value="">-- Phương thức thanh toán --</option>
												<option value="SSS_BALANCE">Sử dụng ví SSStudy</option>
												<option value="BANK_TRANSFER">Chuyển khoản ngân hàng</option>
												<option value="COD">Ship COD (Thanh toán khi nhận hàng)</option>
												<option value="BUY_BOOKS">Mua trực tiếp</option>
											</select>
										</div>
									
									
										<DatePicker
											format={"DD/MM/YYYY"}
											onChange={this.changeDateStart}
											placeholder='Từ ngày'
											className='ml-2'
										/>
										<DatePicker
											format={"DD/MM/YYYY"}
											onChange={this.changeDateEnd}
											placeholder='Đến ngày'
											className='ml-2'
										/>
										<button 
											style={{ marginLeft: "10px" }} 
											class="btn btn-white btn-sm" 
											type="button" 
											onClick={this.resetDataFilter}>
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle mx-2">
													<circle cx="12" cy="12" r="10"></circle>
													<line x1="15" y1="9" x2="9" y2="15"></line>
													<line x1="9" y1="9" x2="15" y2="15"></line>
												</svg>
												</span>
											</button>
										{/* <button
											onClick={this.onSubmit}
											className='btn btn-sm btn-primary text-muted ml-2'
										>
											<span className='d-none d-sm-inline mx-1'>
												Tìm kiếm
											</span>
										</button> */}
									</div>
								</form>
							</div>

							<div className="row">
								<div className="col-sm-12">
									<table className="table table-theme table-row v-middle">
										<thead className="text-muted">
											<tr>
												<th width="10px">
													<label className="ui-check m-0">
														<input
															type="checkbox"
															name="checkAll"
															id="checkAll"
															onChange={
																this.handleCheckAll
															}
														/>{' '}
														<i />
													</label>
												</th>
												<th>Ngày đặt hàng</th>
												<th className="text-left">
													khách hàng
												</th>
												<th width="125px" className="text-left">
													SĐT
												</th>
												<th className="text-left">
													Phương thức
												</th>
												<th className="text-left">
													Số Tiền (vnđ)
												</th>
												<th className="text-left">
													Giảm giá
												</th>
												<th className="text-left">
													Trạng thái
												</th>
												<th width="50px" />
											</tr>
										</thead>
										<tbody>{this.fetchRows()}</tbody>
									</table>
								</div>
							</div>

							<div className="row listing-footer">
								<div className="col-sm-2">
									<select
										className="custom-select w-96"
										name="limit"
										value={this.state.limit}
										onChange={this.handleChange}>
										<option value="20">20</option>
										<option value="50">50</option>
										<option value="100">100</option>
										<option value="-1">ALL</option>
									</select>
								</div>
								<div className="col-sm-5 showing-text">
									{' '}
									Hiển thị từ <b>{displayFrom}</b> đến{' '}
									<b>{displayTo}</b> trong tổng số{' '}
									<b>{this.props.total}</b>
								</div>
								{this.props.total !== 0 ? (
									<div className="col-sm-5 text-right">
										<Pagination
											activePage={this.props.page}
											itemsCountPerPage={this.props.limit}
											totalItemsCount={this.props.total}
											pageRangeDisplayed={10}
											onChange={this.handleChangePage}
										/>
									</div>
								) : (
									<div className="">Không có bản ghi nào</div>
								)}
							</div>

							<div
								id="delete-video"
								className="modal fade"
								data-backdrop="true"
								style={{ display: 'none' }}
								aria-hidden="true">
								<div
									className="modal-dialog animate fade-down"
									data-class="fade-down">
									<div className="modal-content">
										<div className="modal-header">
											<div className="modal-title text-md">
												Thông báo
											</div>
											<button
												className="close"
												data-dismiss="modal">
												×
											</button>
										</div>
										<div className="modal-body">
											<div className="p-4 text-center">
												<p>
													Bạn chắc chắn muốn xóa bản
													ghi này chứ?
												</p>
											</div>
										</div>
										<div className="modal-footer">
											<button
												type="button"
												className="btn btn-light"
												data-dismiss="modal">
												Đóng
											</button>
											<button
												type="button"
												onClick={this.handleDelete}
												className="btn btn-danger"
												data-dismiss="modal">
												Xoá
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		orders: state.order.orders,
		limit: state.order.limit,
		total: state.order.total,
		page: state.order.page,
		ids: state.order.ids,
		check: state.order.checkAll,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			listOrder
		},
		dispatch,
	);
}

let Container = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Order),
);
export default Container;
