import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { Table } from "antd";
import { classroomReport } from "../../redux/classroom/action";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class ClassroomReport extends Component {
	constructor(props) {
		super(props);
		const date = new Date();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();
		this.state = {
			month: month,
			year: year,
			headers: [
			],
		};
	}

	onChange = (e) => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};

	getData = () => {
		const data = {
			classroom_id: this.props.match.params.id,
			month: parseInt(this.state.month),
			year: parseInt(this.state.year),
		};

		return data;
	};

	async componentDidMount() {
		await this.props.classroomReport(this.getData());
	}

	onSubmit = (e) => {
		e.preventDefault();
		this.props.classroomReport(this.getData());
	};

	handleChange = async (e) => {
		var name = e.target.name;
		var value = e.target.value;
		await this.setState({
			[name]: value,
		});
		await this.props.classroomReport(this.getData());
	};

	handleCheckAll = (e) => {
		if (e.target.checked) {
			this.props.checkAll(true);
			this.setState({
				checkAll: e.target.checked,
			});
		} else {
			this.props.checkAll(false);
			this.setState({
				checkAll: e.target.checked,
			});
		}
	};

	fetchMonth = () => {
		const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
		return month.map((month) => {
			return (
				<option value={month} key={month}>
					Tháng {month}
				</option>
			);
		});
	};

	fetchYear = () => {
		const datetime = new Date();

		const yearNow = datetime.getFullYear();

		const yearAgo = datetime.getFullYear() - 2;

		var arr = [];
		var i;
		for (i = parseInt(yearAgo); i <= parseInt(yearNow); i++) {
			arr.push(i);
		}
		if (arr.length !== 0) {
			return arr.map((year) => {
				return (
					<option value={year} key={year}>
						Năm {year}
					</option>
				);
			});
		}
	};

	render() {
		const columns = this.props.columns;

		const data = this.props.members;
		var reportData = [];
		if (data) {
			for (let i = 0; i < data.length; i++) {
				const _obj = {
					stt: data[i].stt,
					code: data[i].code,
					name: data[i].fullname
				};

				for (let j = 0; j < columns.length; j++) {
					if (['code', 'name', 'stt', 'average'].indexOf(columns[j].key) < 0) {
						if (columns[j].dataIndex) {
							_obj[columns[j].dataIndex] = data[i][columns[j].dataIndex] ? data[i][columns[j].dataIndex] : 0;
							_obj[columns[j].key] = data[i][columns[j].dataIndex] ? data[i][columns[j].dataIndex] : 0;
						}
					}
				}

				_obj.average = data[i].average;
				reportData.push(_obj);
			}
		}

		return (
			<div>
				<div className='page-hero page-container' id='page-hero'>
					<div className='padding d-flex'>
						<div className='page-title'>
							<h2 className='text-md text-highlight'>
								Báo cáo điểm:{" "}
								{this.props.classroom !== null
									? this.props.classroom.name
									: ""}
							</h2>
							<small className='text-muted'>Báo cáo điểm</small>
						</div>
						<div className='flex' />
						<div>
							<Link
								to={"/classroom"}
								className='btn btn-sm text-white btn-primary'
							>
								<span className='d-none d-sm-inline mx-1'>
									Quay lại
								</span>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width={16}
									height={16}
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									strokeWidth={2}
									strokeLinecap='round'
									strokeLinejoin='round'
									className='feather feather-arrow-right'
								>
									<line x1={5} y1={12} x2={19} y2={12} />
									<polyline points='12 5 19 12 12 19' />
								</svg>
							</Link>
						</div>
					</div>
				</div>
				<div className='page-content page-container' id='page-content'>
					<div className='padding'>
						<div className='mb-5'>
							<div className='toolbar'>
								<form className='flex' onSubmit={this.onSubmit}>
									<div className='input-group'>
										<select
											style={{
												marginRight: 20,
												maxWidth: 300,
											}}
											className='custom-select'
											name='month'
											onChange={this.handleChange}
											value={this.state.month}
										>
											<option value=''>Chọn tháng</option>
											{this.fetchMonth()}
										</select>
										<select
											style={{
												marginRight: 20,
												maxWidth: 300,
											}}
											className='custom-select'
											name='year'
											onChange={this.handleChange}
											value={this.state.year}
										>
											{this.fetchYear()}
										</select>
										<div style={{ margin: "7px 15px" }}>
											<CSVLink filename={new Date().getTime() + '.csv'} headers={this.props.columns} className="btn btn-sm" data={reportData}>Xuất Excel</CSVLink>
										</div>
										<div style={{ margin: "7px 15px" }}>
											Sĩ số:{" "}
											<strong
												style={{ color: "#007bff" }}
											>
												{this.props.totalStudent}
											</strong>
										</div>
										<div style={{ margin: "7px 15px" }}>
											ĐTB Lớp:{" "}
											<strong
												style={{ color: "#007bff" }}
											>
												{!isNaN(
													Math.round(
														this.props.avgPoint * 10
													) / 10
												)
													? Math.round(
														this.props
															.avgPoint * 10
													) / 10
													: 0}
											</strong>
										</div>
									</div>
								</form>
							</div>

							<div className='row mt-3'>
								<div className='col-12'>
									<Table
										pagination={false}
										columns={columns}
										dataSource={data}
										scroll={{ x: 1500, y: "100%" }}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		members: state.classroom.members,
		columns: state.classroom.columns,
		classroom: state.classroom.classroom,
		totalStudent: state.classroom.total_student,
		avgPoint: state.classroom.avg_point,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ classroomReport }, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ClassroomReport)
);
