import React, {Component} from 'react';
import {notification} from 'antd';
import {createCode, listCode} from '../../redux/classroom/action';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

class ModalCreateCode extends Component {
	constructor(props) {
		super();
		this.state = {
			classroom_id: '',
			total_code: 0,
		};
	}

	onChange = e => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};

	handleSubmit = async () => {
		if (this.state.total_code !== '') {
			const data = {
				total_code: this.state.total_code,
				classroom_id: this.props.classroom_id,
			};
			await this.props.createCode(data);
			const dataCode = {
				classroom_id: this.props.classroom_id,
				page: 1,
				limit: this.props.limit,
			};
			await this.props.listCode(dataCode);
			await this.setState({
				total_code: 0,
			});
		} else {
			notification.warning({
				message: 'Số lượng không được bỏ trống !',
				placement: 'topRight',
				top: 50,
				duration: 3,
				style: {
					zIndex: 1050,
				},
			});
		}
	};

	render() {
		return (
			<div
				className="modal-dialog animate fade-down modal-lg"
				data-class="fade-down">
				<div className="modal-content">
					<div className="modal-header">
						<div className="modal-title text-md">
							Tạo mã truy cập
						</div>
						<button className="close" data-dismiss="modal">
							×
						</button>
					</div>
					<div
						className="modal-body"
						style={{
							minHeight: 150,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<div className="row" style={{minWidth: 400}}>
							<div className="col-md-7">
								<div className="toolbar">
									<div className="input-group">
										<input
											type="number"
											className="form-control keyword-custom"
											placeholder="Nhập số lượng..."
											min="0"
											onChange={this.onChange}
											name="total_code"
											value={this.state.total_code}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-5">
								<button
									data-dismiss={
										this.state.total_code !== '' && 'modal'
									}
									className="btn btn-primary "
									onClick={this.handleSubmit}>
									Tạo
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		limit: state.classroom.limit,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({createCode, listCode}, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ModalCreateCode),
);
