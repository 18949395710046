import axios from 'axios';
import * as ActionTypes from './type';
import { initAPI, responseError, notify } from '../../config/api';

export function listMagazines(data) {
	initAPI();
	return async dispatch => {
		await axios
			.post(`/magazine/list`, data)
			.then(res => {
				notify(res, false);
				if (res.data.code === 200) {
					dispatch({ type: 'PAGING', page: data.page });
					const magazines = res.data.data.records;
					const total = res.data.data.totalRecord;
					const limit = 20;
					dispatch({
						type: ActionTypes.LIST_MAGAZINE,
						magazines,
						total,
						limit,
					});
				}
			})
			.catch(async err => {
				responseError(err);
			});
	};
}

export function showMagazine(id) {
	initAPI();
	return async (dispatch) => {
		const data = {
			id
		};
		await axios.post(`magazine/detail`, data)
			.then(res => {
				notify(res, false);
				if (res.data.code === 200) {
					let magazine = res.data.data;
					dispatch({ type: ActionTypes.SHOW_MAGAZINE ,magazine });
				}
			})
			.catch(async err => {
				responseError(err);
			});
	}
}

export function createMagazine(data) {
	initAPI();
	return async (dispatch) => {
		await axios.post(`/magazine/create`, data)
			.then((res) => {
				notify(res);
				if (res.data.code === 200) {
					let magazine = res.data.data;
					dispatch({
						type: ActionTypes.CREATE_MAGAZINE,
						magazine,
						redirect: true,
					})
				}
			})
	}
}

export function updateMagazine(data) {
	initAPI();
	return async (dispatch) => {
		await axios.post('magazine/update', data)
			.then((res) => {
				notify(res);
				if (res.data.code === 200) {
					dispatch({
						type: ActionTypes.UPDATE_MAGAZINE,
						redirect: true
					})
				}
			})
			.catch((err) => {
				console.log(err);
			})
	}
}

export function addDataRemoveMagazine(data) {
	initAPI();
	return async (dispatch) => {
		dispatch({
			type:ActionTypes.DATA_REMOVE_MAGAZINE,
			dataRemoveMagazine: data
		})
	}
}

export function onDeleteMagazine(data, isActionRemove = true) {
	initAPI();
	return async (dispatch) => {
		await axios.post('/magazine/delete', data) 
			.then((res) => {
				if (res.data.code === 200 || res.data.data.code === 200) {
					notify(res);
					if (isActionRemove ) {
						dispatch({
							type: ActionTypes.DELETE_MAGAZINE
						})
					}
				}
			})
			.catch((err) => {
				console.log(err);
			})
	}
}
