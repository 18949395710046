export const LIST_SCHEDULE = "LIST_SCHEDULE";

export const SCHEDULE_CREATE = "SCHEDULE_CREATE";
export const CREATE_SUCCESS = "CREATE_SUCCESS";
export const CREATE_ERROR = "CREATE_ERROR";

export const SHOW_SCHEDULE = "SHOW_SCHEDULE";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_ERROR = "UPDATE_ERROR";

export const DELETE_SCHEDULE = "DELETE_SCHEDULE";
export const ADD_DELETE = "ADD_DELETE";
export const CHECK_ALL = "CHECK_ALL";

export const LIST_CLASSROOM = "LIST_CLASSROOM";
export const LIST_SUBJECT = "LIST_SUBJECT";

export const RESET_STATE_SCHEDULE = "RESET_STATE_SCHEDULE";
