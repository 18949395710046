import moment from 'moment'

class BaseHelper {

    currencyFormat(value) {
        return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    formatDateToString(value) {
        return moment(value).format('DD/MM/YYYY HH:mm:ss')
      }

}

export default new BaseHelper();
