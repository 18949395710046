export const LIST_STUDENT = 'LIST_STUDENT';
export const CREATE_STUDENT = 'CREATE_STUDENT';
export const SHOW_STUDENT = 'SHOW_STUDENT';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const DELETE_STUDENT = 'DELETE_STUDENT';
export const ADD_DELETE = 'ADD_DELETE';
export const CHECK_ALL = 'CHECK_ALL';
export const ADMIN_CREATE = 'ADMIN_CREATE';
export const ADMIN_UPDATE = 'ADMIN_UPDATE';
export const CHECK_CODE = "CHECK_CODE";
export const LIST_CLASSROOM = "LIST_CLASSROOM";
export const LIST_ACCOUNTANT = "LIST_ACCOUNTANT";
export const CREDIT_CREATE = "CREDIT_CREATE";
export const DATA_REMOVE_STUDENT = 'DATA_REMOVE_STUDENT';
export const DATA_REMOVE_ADMIN = 'DATA_REMOVE_ADMIN';