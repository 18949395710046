import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { listSubject } from "../../redux/subject/action";
import {
  showBook,
  updateBook,
  listBookCategory,
} from "../../redux/book/action";
import { listAdmin } from "../../redux/student/action";

import { uploadImage } from "../../redux/category/action";
import { Editor } from "@tinymce/tinymce-react";
import ModalAttachedClassroom from "./ModalAttachedClassroom";
import ModalBookRelate from "./ModalBookRelate";
import ModalClassroomRelate from "./ModalClassroomRelate";
import { notification, DatePicker, LocaleProvider } from "antd";
import enUS from 'antd/lib/locale-provider/en_US';
import moment from "moment";
class BookEdit extends Component {
  constructor(props) {
    super();
    this.state = {
      name: "",
      subject_id: "",
      category_id: "",
      content: "",
      description: "",
      price: "",
      origin_price: "",
      files: [],
      external_link: "",
      status: true,
      is_featured: false,
      files: [],
      checkedTrue: null,
      checkedFlase: null,
      ordering: 0,
      teacher_id: null,
      code: "",
      promotion: {
        // type: '',
        // hour: null,
        from_date: null,
        to_date: null
      }
    };
  }

  async componentDidMount() {
    const data = {
      limit: 999,
      is_delete: false,
    };
    await this.props.listSubject(data);
    await this.props.listBookCategory(data);
    await this.props.showBook(this.props.match.params.id);

    const params = {
      user_group: "TEACHER",
      limit: 100,
    };
    await this.props.listAdmin(params);
    if (this.props.book) {
      var {
        name,
        price,
        origin_price,
        content,
        description,
        status,
        is_featured,
        ordering,
        external_link,
        files,
        teacher_id,
        promotion,
        code
      } = this.props.book;
      this.setState({
        name,
        subject_id: this.props.book.subject.id,
        category_id: this.props.book.category
          ? this.props.book.category.id
          : "",
        content,
        price,
        external_link,
        origin_price,
        status,
        is_featured,
        files,
        ordering,
        description,
        teacher_id,
        code,
        promotion: promotion ? promotion : null
      });
    }
  }

  _onChange = async (e) => {
    var name = e.target.name;
    let value = e.target.value;
    if (name === "files") {
      value = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
      });
      value = [value];
    }
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let { promotion } = this.state;

    const data = {
      id: this.props.match.params.id,
      name: this.state.name,
      subject_id: this.state.subject_id,
      content: this.state.content,
      description: this.state.description,
      category_id: this.state.category_id,
      status: this.state.status,
      external_link: this.state.external_link,
      price: this.state.price,
      origin_price: this.state.origin_price,
      files: this.state.files,
      ordering: this.state.ordering,
      is_featured: this.state.is_featured,
      teacher_id: this.state.teacher_id,
      code: this.state.code,
      promotion: promotion && promotion.to_date || promotion && promotion.from_date ? promotion : null
    };
    this.props.updateBook(data);
  };

  fetchSubjectRows() {
    if (this.props.subjects instanceof Array) {
      return this.props.subjects.map((obj, i) => {
        return <option key={obj._id} value={obj._id}>{obj.name}</option>;
      });
    }
  }

  fetchCategoryRows() {
    if (this.props.bookCategories instanceof Array) {
      return this.props.bookCategories.map((obj, i) => {
        return <option key={obj._id} value={obj._id}>{obj.name}</option>;
      });
    }
  }

  fetchTeacherRows() {
    if (this.props.students instanceof Array) {
      return this.props.students.map((obj, i) => {
        return (
          <option value={obj._id} key={obj._id.toString()}>
            {obj.fullname}
          </option>
        );
      });
    }
  }

  _handleEditorContentChange = (value, editor) => {
    this.setState({ content: value });
  };
  _handleEditorDescriptionChange = (value, editor) => {
    this.setState({ description: value });
  };

  _uploadImageCallBack = async (blobInfo, success, failure) => {
    let file = blobInfo.blob();
    const data = new FormData();
    data.append("files", file);

    await this.props.uploadImage(data);

    if (this.props.image != null) {
      success(this.props.image);
    } else {
      failure("Upload image fail");
    }
  };

  changeDateStart = (date, dateString) => {
    if (date !== null) {
      this.setState({
        promotion: {
          ...this.state.promotion,
          from_date: date.format("YYYY/MM/DD HH:mm"),
        }
      });
    }
  };

  changeDateEnd = (date, dateString) => {
    if (date !== null) {
      this.setState({
        promotion: {
          ...this.state.promotion,
          to_date: date.format("YYYY/MM/DD HH:mm"),
        }
      });
    }
  };

  render() {
    const { promotion } = this.state;


    return (
      <div>
        <div className="page-hero page-container" id="page-hero">
          <div className="padding d-flex">
            <div className="page-title">
              <h2 className="text-md text-highlight">Cập nhật sách</h2>
            </div>
            <div className="flex" />
            <div>

              <button
                className="btn btn-primary btn-sm mr-2"
                data-toggle="modal"
                data-target="#classroom-attached"
                data-toggle-class="fade-down"
                data-toggle-class-target=".animate"
              >
                Khóa học đi kèm
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-file-plus mx-2"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                  <polyline points="14 2 14 8 20 8" />
                  <line x1={12} y1={18} x2={12} y2={12} />
                  <line x1={9} y1={15} x2={15} y2={15} />
                </svg>
              </button>

              <button
                className="btn btn-primary btn-sm mr-2"
                data-toggle="modal"
                data-target="#classroom-relate"
                data-toggle-class="fade-down"
                data-toggle-class-target=".animate"
              >
                Khóa học đề xuất
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-file-plus mx-2"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                  <polyline points="14 2 14 8 20 8" />
                  <line x1={12} y1={18} x2={12} y2={12} />
                  <line x1={9} y1={15} x2={15} y2={15} />
                </svg>
              </button>

              <button
                className="btn btn-primary btn-sm mr-2"
                data-toggle="modal"
                data-target="#book-relate"
                data-toggle-class="fade-down"
                data-toggle-class-target=".animate"
              >
                Sách đề xuất
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-file-plus mx-2"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                  <polyline points="14 2 14 8 20 8" />
                  <line x1={12} y1={18} x2={12} y2={12} />
                  <line x1={9} y1={15} x2={15} y2={15} />
                </svg>
              </button>


              <Link to={"/book"} className="btn btn-sm text-white btn-primary">
                <span className="d-none d-sm-inline mx-1">Quay lại</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-right"
                >
                  <line x1={5} y1={12} x2={19} y2={12} />
                  <polyline points="12 5 19 12 12 19" />
                </svg>
              </Link>
            </div>
          </div>
        </div>

        <div className="page-content page-container" id="page-content">
          <div className="padding">
            <div className="row">
              <div className="col-md-10">
                <div className="card">
                  <div className="card-header">
                    <strong>Cập nhật thông tin sách</strong>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className="col-form-label">Tên</label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            onChange={this._onChange}
                            value={this.state.name}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label className="col-form-label">Mã sách</label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            name="code"
                            onChange={this._onChange}
                            value={this.state.code}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className=" col-form-label">Môn học</label>
                        <div className="">
                          <select
                            className="custom-select"
                            value={this.state.subject_id}
                            name="subject_id"
                            onChange={this._onChange}
                          >
                            <option value="">-- Chọn môn học --</option>
                            {this.fetchSubjectRows()}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label className=" col-form-label">Danh mục sách</label>
                        <div className="">
                          <select
                            className="custom-select"
                            value={this.state.category_id}
                            name="category_id"
                            onChange={this._onChange}
                          >
                            <option value="">-- Chọn danh mục --</option>
                            {this.fetchCategoryRows()}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className="col-form-label">Giá</label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            name="price"
                            onChange={this._onChange}
                            value={this.state.price}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label className="col-form-label">Giá gốc</label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            name="origin_price"
                            onChange={this._onChange}
                            value={this.state.origin_price}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className="col-form-label">
                          Link Landing Page
                        </label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            name="external_link"
                            onChange={this._onChange}
                            value={this.state.external_link ? this.state.external_link : ''}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label className="col-form-label">Thứ tự</label>
                        <div>
                          <input
                            type="number"
                            className="form-control"
                            name="ordering"
                            onChange={this._onChange}
                            value={this.state.ordering}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className="col-form-label">Chọn giáo viên</label>
                        <div>
                          <select
                            className="custom-select"
                            value={this.state.teacher_id ? this.state.teacher_id : ''}
                            name="teacher_id"
                            onChange={this._onChange}
                          >
                            <option value="">-- Chọn giáo viên --</option>
                            {this.fetchTeacherRows()}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className="col-sm-4 col-form-label">
                          Hình ảnh
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="file"
                            onChange={this._onChange}
                            className="form-control-file"
                            name="files"
                          />
                        </div>
                        <div className="col-sm-4 d-flex">
                          <img
                            alt=""
                            src={this.props.book ? this.props.book.image : ""}
                            style={{ width: "200px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className=" col-form-label">Mô tả</label>
                        <div>
                          <Editor
                            onInit={(evt, editor) => {
                              this._handleEditorDescriptionChange(
                                this.state.description,
                                editor
                              );
                            }}
                            value={this.state.description}
                            init={{
                              height: 500,
                              menubar: false,
                              images_file_types:
                                "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                              plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                              ],
                              external_plugins: {
                                tiny_mce_wiris:
                                  "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                              },
                              toolbar:
                                "undo redo | formatselect | " +
                                "bold italic backcolor | image | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                                "searchreplace visualblocks code fullscreen | " +
                                "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                              fontsize_formats:
                                "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              draggable_modal: true,
                              htmlAllowedTags: [".*"],
                              htmlAllowedAttrs: [".*"],
                              images_upload_handler: (
                                blobInfo,
                                success,
                                failure
                              ) =>
                                this._uploadImageCallBack(
                                  blobInfo,
                                  success,
                                  failure
                                ),
                            }}
                            onEditorChange={this._handleEditorDescriptionChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className=" col-form-label">Nội dung</label>
                        <div>
                          <Editor
                            onInit={(evt, editor) => {
                              this._handleEditorContentChange(
                                this.state.content,
                                editor
                              );
                            }}
                            value={this.state.content}
                            init={{
                              height: 500,
                              menubar: false,
                              images_file_types:
                                "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                              plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                              ],
                              external_plugins: {
                                tiny_mce_wiris:
                                  "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                              },
                              toolbar:
                                "undo redo | formatselect | " +
                                "bold italic backcolor | image | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                                "searchreplace visualblocks code fullscreen | " +
                                "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                              fontsize_formats:
                                "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              draggable_modal: true,
                              htmlAllowedTags: [".*"],
                              htmlAllowedAttrs: [".*"],
                              images_upload_handler: (
                                blobInfo,
                                success,
                                failure
                              ) =>
                                this._uploadImageCallBack(
                                  blobInfo,
                                  success,
                                  failure
                                ),
                            }}
                            onEditorChange={this._handleEditorContentChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className=" col-form-label">Trạng thái</label>
                        <div>
                          <div className="form-check float-left">
                            <input
                              checked={
                                this.state.status === true ||
                                this.state.status === "true"
                              }
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value="true"
                              id="gridRadios1"
                              onChange={this._onChange}
                              defaultValue="option1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridRadios1"
                            >
                              Hiển thị
                            </label>
                          </div>
                          <div className="form-check float-left ml-4">
                            <input
                              checked={
                                this.state.status === false ||
                                this.state.status === "false"
                              }
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value="false"
                              id="gridRadios2"
                              onChange={this._onChange}
                              defaultValue="option2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridRadios2"
                            >
                              Ẩn
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className=" col-form-label">Trạng thái</label>
                        <div>
                          <div className="form-check float-left">
                            <input
                              checked={
                                this.state.is_featured === false ||
                                this.state.is_featured === "false"
                              }
                              className="form-check-input"
                              type="radio"
                              name="is_featured"
                              value={false}
                              id="gridRadios1"
                              onChange={this._onChange}
                              defaultValue="option1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridRadios1"
                            >
                              Mặc định
                            </label>
                          </div>
                          <div className="form-check float-left ml-4">
                            <input
                              checked={
                                this.state.is_featured === true ||
                                this.state.is_featured === "true"
                              }
                              className="form-check-input"
                              type="radio"
                              name="is_featured"
                              value={true}
                              id="gridRadios2"
                              onChange={this._onChange}
                              defaultValue="option2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridRadios2"
                            >
                              Nổi bật
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className="col-form-label">Áp dụng khuyến mại </label>

                        <DatePicker
                          format={
                            "YYYY/MM/DD HH:mm"
                          }
                          value={promotion && promotion.from_date
                            ? moment(promotion.from_date)
                            : null}
                          showTime={{ format: 'HH:mm' }}
                          placeholder="Từ ngày"
                          onChange={this.changeDateStart}
                          className="ml-2"
                        />
                        <DatePicker
                          format={
                            "YYYY/MM/DD HH:mm"
                          }
                          value={promotion && promotion.to_date
                            ? moment(promotion.to_date)
                            : null}
                          showTime={{ format: 'HH:mm' }}
                          placeholder="Đến ngày"
                          onChange={this.changeDateEnd}
                          className="ml-2"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12 text-right">
                        <button
                          className="btn btn-primary mt-2"
                          onClick={this.handleSubmit}
                        >
                          Cập nhật
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalAttachedClassroom book={this.props.book} classroomAttached={this.props.classroomAttached} />
        <ModalBookRelate book={this.props.book} bookRelates={this.props.bookRelates} />
        <ModalClassroomRelate book={this.props.book} classroomRelates={this.props.classroomRelates} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    subjects: state.subject.subjects,
    book: state.book.book,
    bookCategories: state.book.bookCategories,
    image: state.question.image,
    classroomAttached: state.book.classroomAttached,
    classroomRelates: state.book.classroomRelates,
    students: state.student.students,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { listSubject, showBook, updateBook, listBookCategory, uploadImage, listAdmin },
    dispatch
  );
}

let ContainerEdit = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookEdit)
);

export default ContainerEdit;
