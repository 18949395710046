import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { listSubject } from "../../redux/subject/action";
import { listAdmin } from "../../redux/student/action";
import { listBookCategory, createBook } from "../../redux/book/action";
import { uploadImage } from "../../redux/category/action";
import { Editor } from "@tinymce/tinymce-react";
import { notification, DatePicker, LocaleProvider } from "antd";
import enUS from 'antd/lib/locale-provider/en_US';
import moment from "moment";

class BookCreate extends Component {
  constructor(props) {
    super();
    this.state = {
      name: "",
      subject_id: "",
      category_id: "",
      description: "",
      content: "",
      status: true,
      is_featured: false,
      price: "",
      code: "",
      origin_price: "",
      files: [],
      external_link: "",
      ordering: 1,
      teacher_id: null,
      promotion: {
        // type: '',
        // hour: null,
        from_date: null,
        to_date: null
      }
    };
  }

  async componentDidMount() {
    const data = {
      limit: 999,
      is_delete: false,
    };
    await this.props.listSubject(data);
    await this.props.listBookCategory(data);

    const params = {
      user_group: "TEACHER",
      limit: 100,
    };
    await this.props.listAdmin(params);
  }

  _onChange = async (e) => {
    var name = e.target.name;
    let value = e.target.value;
    if (name === "files") {
      value = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
      });
      value = [value];
    }
    this.setState({
      [name]: value,
    });
    // if (name === "subject_id") {
    //   let params = {};

    //   if (value) {
    //     params = {
    //       subject_id: value,
    //       limit: 100,
    //     };
    //     await this.props.listClassroomGroup(params);

    //     params = {
    //       user_group: "TEACHER",
    //       subject_id: value,
    //       limit: 100,
    //     };
    //     await this.props.listStudent(params);
    //   }
    // }
  };

  handleSubmit = async (type) => {
    let { promotion } = this.state;
    const data = {
      name: this.state.name,
      subject_id: this.state.subject_id,
      category_id: this.state.category_id,
      is_featured: this.state.is_featured,
      files: this.state.files,
      external_link: this.state.external_link,
      price: isNaN(this.state.price) ? 0 : this.state.price,
      origin_price: isNaN(this.state.origin_price)
        ? 0
        : this.state.origin_price,
      ordering: this.state.ordering,
      content: this.state.content,
      description: this.state.description,
      teacher_id: this.state.teacher_id,
      code: this.state.code
      // promotion: this.state.promotion.to_date && this.state.promotion.from_date ? this.state.promotion : {}
    };

    if (promotion.to_date || promotion.from_date) {
      data.promotion = promotion;
    }

    if (type === 0) {
      await this.props.createBook(data);
      if (this.props.redirect === true && this.props.book) {
        await this.props.history.push("/book");
      }
    } else {
      await this.props.createBook(data);
    }
  };

  fetchSubjectRows() {
    if (this.props.subjects instanceof Array) {
      return this.props.subjects.map((obj, i) => {
        return <option key={i} value={obj._id}>{obj.name}</option>;
      });
    }
  }

  fetchCategoryRows() {
    if (this.props.bookCategories instanceof Array) {
      return this.props.bookCategories.map((obj, i) => {
        return <option key={i} value={obj._id}>{obj.name}</option>;
      });
    }
  }

  _handleEditorContentChange = (value, editor) => {
    this.setState({ content: value });
  };
  _handleEditorDescriptionChange = (value, editor) => {
    this.setState({ description: value });
  };

  _uploadImageCallBack = async (blobInfo, success, failure) => {
    let file = blobInfo.blob();
    const data = new FormData();
    data.append("files", file);

    await this.props.uploadImage(data);

    if (this.props.image != null) {
      success(this.props.image);
    } else {
      failure("Upload image fail");
    }
  };

  fetchTeacherRows() {
    if (this.props.students instanceof Array) {
      return this.props.students.map((obj, i) => {
        return (
          <option value={obj._id} key={obj._id.toString()}>
            {obj.fullname}
          </option>
        );
      });
    }
  }

  changeDateStart = (date, dateString) => {
    if (date !== null) {
      this.setState({
        promotion: {
          ...this.state.promotion,
          from_date: date.format("YYYY/MM/DD HH:mm"),
        }
      });
    }
  };

  changeDateEnd = (date, dateString) => {
    if (date !== null) {
      this.setState({
        promotion: {
          ...this.state.promotion,
          to_date: date.format("YYYY/MM/DD HH:mm"),
        }
      });
    }
  };

  render() {
    const { promotion } = this.state;
    return (
      <div>
        <div className="page-hero page-container" id="page-hero">
          <div className="padding d-flex">
            <div className="page-title">
              <h2 className="text-md text-highlight">Thêm sách mới</h2>
            </div>
            <div className="flex" />
            <div>
              <Link to={"/book"} className="btn btn-sm text-white btn-primary">
                <span className="d-none d-sm-inline mx-1">Quay lại</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-right"
                >
                  <line x1={5} y1={12} x2={19} y2={12} />
                  <polyline points="12 5 19 12 12 19" />
                </svg>
              </Link>
            </div>
          </div>
        </div>

        <div className="page-content page-container" id="page-content">
          <div className="padding">
            <div className="row">
              <div className="col-md-10">
                <div className="card">
                  <div className="card-header">
                    <strong>Thông tin sách</strong>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className="col-form-label">Tên</label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            onChange={this._onChange}
                            value={this.state.name}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label className="col-form-label">Mã sách</label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            name="code"
                            onChange={this._onChange}
                            value={this.state.code}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className=" col-form-label">Môn học</label>
                        <div className="">
                          <select
                            className="custom-select"
                            value={this.state.subject_id}
                            name="subject_id"
                            onChange={this._onChange}
                          >
                            <option value="">-- Chọn môn học --</option>
                            {this.fetchSubjectRows()}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label className=" col-form-label">Danh mục sách</label>
                        <div className="">
                          <select
                            className="custom-select"
                            value={this.state.category_id}
                            name="category_id"
                            onChange={this._onChange}
                          >
                            <option value="">-- Chọn danh mục --</option>
                            {this.fetchCategoryRows()}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className="col-form-label">Giá</label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            name="price"
                            onChange={this._onChange}
                            value={this.state.price}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label className="col-form-label">Giá gốc</label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            name="origin_price"
                            onChange={this._onChange}
                            value={this.state.origin_price}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className="col-form-label">
                          Link Landing Page
                        </label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            name="external_link"
                            onChange={this._onChange}
                            value={this.state.external_link}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <label className="col-form-label">Thứ tự</label>
                        <div>
                          <input
                            type="number"
                            className="form-control"
                            name="ordering"
                            onChange={this._onChange}
                            value={this.state.ordering}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label className=" col-form-label">Hình ảnh</label>
                        <div className="">
                          <input
                            onChange={this._onChange}
                            type="file"
                            className="form-control-file"
                            name="files"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label className="col-form-label">Chọn giáo viên</label>
                        <div>
                          <select
                            className="custom-select"
                            value={this.state.teacher_id}
                            name="teacher_id"
                            onChange={this._onChange}
                          >
                            <option value="">-- Chọn giáo viên --</option>
                            {this.fetchTeacherRows()}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <label className=" col-form-label">Mô tả</label>
                          <div>
                            <Editor
                              onInit={(evt, editor) => {
                                this._handleEditorDescriptionChange(
                                  this.state.description,
                                  editor
                                );
                              }}
                              value={this.state.description}
                              init={{
                                height: 500,
                                menubar: false,
                                images_file_types:
                                  "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                                ],
                                external_plugins: {
                                  tiny_mce_wiris:
                                    "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                },
                                toolbar:
                                  "undo redo | formatselect | " +
                                  "bold italic backcolor | image | alignleft aligncenter " +
                                  "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                                  "searchreplace visualblocks code fullscreen | " +
                                  "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                                fontsize_formats:
                                  "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                                content_style:
                                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                draggable_modal: true,
                                htmlAllowedTags: [".*"],
                                htmlAllowedAttrs: [".*"],
                                images_upload_handler: (
                                  blobInfo,
                                  success,
                                  failure
                                ) =>
                                  this._uploadImageCallBack(
                                    blobInfo,
                                    success,
                                    failure
                                  ),
                              }}
                              onEditorChange={
                                this._handleEditorDescriptionChange
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <label className=" col-form-label">Nội dung</label>
                          <div>
                            <Editor
                              onInit={(evt, editor) => {
                                this._handleEditorContentChange(
                                  this.state.content,
                                  editor
                                );
                              }}
                              value={this.state.content}
                              init={{
                                height: 500,
                                menubar: false,
                                images_file_types:
                                  "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                                ],
                                external_plugins: {
                                  tiny_mce_wiris:
                                    "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                },
                                toolbar:
                                  "undo redo | formatselect | " +
                                  "bold italic backcolor | image | alignleft aligncenter " +
                                  "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                                  "searchreplace visualblocks code fullscreen | " +
                                  "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                                fontsize_formats:
                                  "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                                content_style:
                                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                draggable_modal: true,
                                htmlAllowedTags: [".*"],
                                htmlAllowedAttrs: [".*"],
                                images_upload_handler: (
                                  blobInfo,
                                  success,
                                  failure
                                ) =>
                                  this._uploadImageCallBack(
                                    blobInfo,
                                    success,
                                    failure
                                  ),
                              }}
                              onEditorChange={this._handleEditorContentChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className=" col-form-label">Trạng thái</label>
                        <div>
                          <div className="form-check float-left">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value="true"
                              id="gridRadios1"
                              onChange={this._onChange}
                              defaultValue="option1"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridRadios1"
                            >
                              Hiển thị
                            </label>
                          </div>
                          <div className="form-check float-left ml-4">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value="false"
                              id="gridRadios2"
                              onChange={this._onChange}
                              defaultValue="option2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridRadios2"
                            >
                              Ẩn
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className=" col-form-label">Nổi bật</label>
                        <div>
                          <div className="form-check float-left">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="is_featured"
                              value={false}
                              id="gridRadios1"
                              onChange={this._onChange}
                              defaultValue="option1"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridRadios1"
                            >
                              Mặc định
                            </label>
                          </div>
                          <div className="form-check float-left ml-4">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="is_featured"
                              value={true}
                              id="gridRadios2"
                              onChange={this._onChange}
                              defaultValue="option2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridRadios2"
                            >
                              Nổi bật
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className="col-form-label">Áp dụng khuyến mại </label>

                          <DatePicker
                            format={
                              "YYYY/MM/DD HH:mm"
                            }
                            value={promotion.from_date
                              ? moment(promotion.from_date)
                              : null}
                            showTime={{ format: 'HH:mm' }}
                            placeholder="Từ ngày"
                            onChange={this.changeDateStart}
                            className="ml-2"
                          />
                          <DatePicker
                            format={
                              "YYYY/MM/DD HH:mm"
                            }
                            value={promotion.to_date
                              ? moment(promotion.to_date)
                              : null}
                            showTime={{ format: 'HH:mm' }}
                            placeholder="Đến ngày"
                            onChange={this.changeDateEnd}
                            className="ml-2"
                          />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12 text-right">
                        <button
                          className="btn btn-primary mt-2"
                          onClick={() => this.handleSubmit(0)}
                        >
                          Lưu
                        </button>
                        <button
                          className="btn btn-primary mt-2 ml-2"
                          onClick={() => this.handleSubmit(1)}
                        >
                          Lưu & Thêm mới
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    subjects: state.subject.subjects,
    book: state.book.book,
    bookCategories: state.book.bookCategories,
    redirect: true,
    image: state.question.image,
    students: state.student.students,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { listSubject, createBook, listBookCategory, uploadImage, listAdmin },
    dispatch
  );
}

let ContainerCreate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookCreate)
);

export default ContainerCreate;
