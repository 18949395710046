import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Input,
  Select,
  Icon,
  message,
  Radio,
  Upload,
  Button,
  notification,
} from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { listChapter } from "../../redux/chapter/action";
import { listSubject } from "../../redux/subject/action";
import { listCategory } from "../../redux/category/action";
import {
  createQuestion,
  uploadImage,
  listQuestion,
} from "../../redux/question/action";
const { Option } = Select;

class CreateQuestion extends Component {
  constructor(props) {
    super();
    this.state = {
      question: null,
      answer: "A",
      answer_content: null,
      doc_link: "",
      video_link: "",
      subject_id: "",
      chapter_id: "",
      category_id: "",
      level: "NHAN_BIET",
      selectedFile: null,

      content: "",

      content1: "",

      uploadedImages: [],
    };
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      this.state.subject_id !== prevState.subject_id &&
      prevState.subject_id !== ""
    ) {
      this.setState({
        chapter_id: "",
      });
    }
  };

  async componentDidMount() {
    const data = {
      limit: 999,
      is_delete: false,
    };
    await this.props.listChapter(data);
    await this.props.listCategory(data);
    await this.props.listSubject(data);
  }

  _onChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  getData = (pageNumber = 1) => {
    const data = {
      page: pageNumber,
      limit: this.props.limit,
    };
    return data;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.subject_id === "") {
      notification.error({
        message: "Môn không được để trống",
        placement: "topRight",
        top: 50,
        duration: 3,
      });
      return false;
    }
    if (this.state.chapter_id === "") {
      notification.error({
        message: "Chương là trường bắt buộc",
        placement: "topRight",
        top: 50,
        duration: 3,
      });
      return false;
    }
    if (this.state.category_id === "") {
      notification.error({
        message: "Danh mục là trường bắt buộc",
        placement: "topRight",
        top: 50,
        duration: 3,
      });
      return false;
    }

    const data = new FormData();
    data.append("answer", this.state.answer);
    data.append("doc_link", this.state.doc_link);
    data.append("video_link", this.state.video_link);
    data.append("subject_id", this.state.subject_id);
    data.append("chapter_id", this.state.chapter_id);
    data.append("category_id", this.state.category_id);
    data.append("level", this.state.level);
    data.append("question", this.state.content.toString());
    data.append("answer_content", this.state.content1.toString());

    await this.props.createQuestion(data);
    await this.props.listQuestion(this.getData());
  };

  handleSave = async (e) => {
    const resetObj = {
      question: "",
      answer: "A",
      doc_link: "",
      video_link: "",
      level: "NHAN_BIET",
      content: "",
      content1: "",
      uploadedImages: [],
    };

    if (e.target && e.target.name === "reset" && e.target.value === "1") {
      resetObj.subject_id = "";
      resetObj.chapter_id = "";
      resetObj.category_id = "";
    }

    e.preventDefault();

    if (this.state.subject_id === "") {
      notification.error({
        message: "Môn không được để trống",
        placement: "topRight",
        top: 50,
        duration: 3,
      });
      return false;
    }
    if (this.state.chapter_id === "") {
      notification.error({
        message: "Chương là trường bắt buộc",
        placement: "topRight",
        top: 50,
        duration: 3,
      });
      return false;
    }
    if (this.state.category_id === "") {
      notification.error({
        message: "Danh mục là trường bắt buộc",
        placement: "topRight",
        top: 50,
        duration: 3,
      });
      return false;
    }

    const data = new FormData();
    data.append("answer", this.state.answer);
    data.append("doc_link", this.state.doc_link);
    data.append("video_link", this.state.video_link);
    data.append("subject_id", this.state.subject_id);
    data.append("chapter_id", this.state.chapter_id);
    data.append("category_id", this.state.category_id);
    data.append("level", this.state.level);
    data.append("question", this.state.content.toString());
    data.append("answer_content", this.state.content1.toString());
    await this.props.createQuestion(data);

    this.setState(resetObj);
    await this.props.listQuestion(this.getData());
  };

  fetchRows() {
    if (this.props.tags instanceof Array) {
      return this.props.tags.map((obj, i) => {
        return <Option key={obj.name.toString()}>{obj.name}</Option>;
      });
    }
  }

  //change select option
  handleChange = (value) => {
    this.setState({
      tags: Object.assign([], value),
    });
  };

  onChangeHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  fetchRowsSubject() {
    if (this.props.subjects instanceof Array) {
      return this.props.subjects.map((obj, i) => {
        return (
          <option value={obj._id} key={obj._id.toString()}>
            {obj.name}
          </option>
        );
      });
    }
  }

  fetchRowsChapter() {
    if (this.props.chapters instanceof Array) {
      return this.props.chapters.map((obj, i) => {
        if (obj.subject.id === this.state.subject_id) {
          return (
            <option value={obj._id} key={obj._id.toString()}>
              {obj.name}
            </option>
          );
        }
      });
    }
  }

  fetchRowsCategory() {
    if (this.props.categories instanceof Array) {
      return this.props.categories.map((obj, i) => {
        if (obj.chapter.id === this.state.chapter_id) {
          return (
            <option value={obj._id} key={obj._id.toString()}>
              {obj.name}
            </option>
          );
        }
      });
    }
  }

  _handleEditorContentChange = (value, editor) => {
    this.setState({ content: value });
  };
  _handleEditorContent1Change = (value, editor) => {
    this.setState({ content1: value });
  };

  _uploadImageCallBack = async (blobInfo, success, failure) => {
    let file = blobInfo.blob();
    const data = new FormData();
    data.append("files", file);

    await this.props.uploadImage(data);

    if (this.props.image != null) {
      success(this.props.image);
    } else {
      failure("Upload image fail");
    }
  };

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <strong>Thêm câu hỏi mới</strong>
            </div>
            <div className="card-body">
              <form ref={(el) => (this.myFormRef = el)}>
                <div className="row">
                  <div className="col-sm-6 col-form-div">
                    <div className="form-group">
                      <label className="col-sm-12 col-form-label">
                        Mã câu hỏi
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          disabled
                          placeholder="Tự động sinh"
                          className="form-control"
                          name="code"
                          onChange={this._onChange}
                          value={this.state.name}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-sm-12 col-form-label">
                        Môn học
                      </label>
                      <div className="col-sm-12">
                        <select
                          className="custom-select"
                          value={this.state.subject_id}
                          name="subject_id"
                          onChange={this._onChange}
                        >
                          <option value="">-- Chọn môn học --</option>
                          {this.fetchRowsSubject()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-form-div">
                    <div className="form-group">
                      <label className="col-sm-12 col-form-label">Chương</label>
                      <div className="col-sm-12">
                        <select
                          className="custom-select"
                          value={this.state.chapter_id}
                          name="chapter_id"
                          onChange={this._onChange}
                        >
                          <option value="">-- Chọn chương --</option>
                          {this.fetchRowsChapter()}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-sm-12 col-form-label">
                        Tên bài
                      </label>
                      <div className="col-sm-12">
                        <select
                          className="custom-select"
                          value={this.state.category_id}
                          name="category_id"
                          onChange={this._onChange}
                        >
                          <option value="">-- Chọn bài --</option>
                          {this.fetchRowsCategory()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-form-div">
                    <div className="form-group">
                      <label className="col-sm-12 col-form-label">
                        Câu hỏi
                      </label>
                      <div className="col-sm-12">
                        <Editor
                          onInit={(evt, editor) => {
                            this._handleEditorContentChange(
                              this.state.content,
                              editor
                            );
                          }}
                          value={this.state.content}
                          init={{
                            height: 500,
                            menubar: false,
                            images_file_types:
                              "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                            ],
                            external_plugins: {
                              tiny_mce_wiris:
                                "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                            },
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | image | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                              "searchreplace visualblocks code fullscreen | " +
                              "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                            fontsize_formats:
                              "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            draggable_modal: true,
                            htmlAllowedTags: [".*"],
                            htmlAllowedAttrs: [".*"],
                            images_upload_handler: (
                              blobInfo,
                              success,
                              failure
                            ) =>
                              this._uploadImageCallBack(
                                blobInfo,
                                success,
                                failure
                              ),
                          }}
                          onEditorChange={this._handleEditorContentChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-form-div">
                    <div className="form-group">
                      <label className="col-sm-12 col-form-label">
                        Lời giải
                      </label>
                      <div className="col-sm-12">
                        <Editor
                          onInit={(evt, editor) => {
                            this._handleEditorContent1Change(
                              this.state.content,
                              editor
                            );
                          }}
                          value={this.state.content1}
                          init={{
                            height: 500,
                            menubar: false,
                            images_file_types:
                              "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                            ],
                            external_plugins: {
                              tiny_mce_wiris:
                                "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                            },
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | image | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                              "searchreplace visualblocks code fullscreen | " +
                              "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                            fontsize_formats:
                              "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            draggable_modal: true,
                            htmlAllowedTags: [".*"],
                            htmlAllowedAttrs: [".*"],
                            images_upload_handler: (
                              blobInfo,
                              success,
                              failure
                            ) =>
                              this._uploadImageCallBack(
                                blobInfo,
                                success,
                                failure
                              ),
                          }}
                          onEditorChange={this._handleEditorContent1Change}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-4 col-form-div">
                    <div className="form-group">
                      <label className="col-sm-8 col-form-label">Đáp án</label>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <Radio.Group
                      onChange={this._onChange}
                      name="answer"
                      value={this.state.answer}
                    >
                      <Radio value={"A"}>A</Radio>
                      <Radio value={"B"}>B</Radio>
                      <Radio value={"C"}>C</Radio>
                      <Radio value={"D"}>D</Radio>
                    </Radio.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-4 col-form-div">
                    <div className="form-group">
                      <label className="col-sm-8 col-form-label">Độ khó</label>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <Radio.Group
                      onChange={this._onChange}
                      name="level"
                      value={this.state.level}
                    >
                      <Radio value="NHAN_BIET">Nhận biết</Radio>
                      <Radio value="THONG_HIEU">Thông hiểu</Radio>
                      <Radio value="VAN_DUNG">Vận dụng</Radio>
                      <Radio value="VAN_DUNG_CAO">Vận dụng cao</Radio>
                    </Radio.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-form-div">
                    <div className="form-group">
                      <label className="col-sm-12 col-form-label">
                        Tài liệu tham khảo
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          className="form-control"
                          name="doc_link"
                          onChange={this._onChange}
                          value={this.state.doc_link}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-form-div">
                    <div className="form-group">
                      <label className="col-sm-12 col-form-label">
                        Video tham khảo
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          className="form-control"
                          name="video_link"
                          onChange={this._onChange}
                          value={this.state.video_link}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12 text-right">
                    <button
                      className="btn btn-primary mt-2"
                      data-dismiss="modal"
                      onClick={this.handleSubmit}
                    >
                      Lưu
                    </button>
                    <button
                      name="reset"
                      value="1"
                      className="btn btn-primary mt-2 ml-2"
                      onClick={this.handleSave}
                    >
                      Lưu & Thêm mới
                    </button>
                    <button
                      name="reset"
                      value="0"
                      className="btn btn-primary mt-2 ml-2"
                      onClick={this.handleSave}
                    >
                      Lưu & Thêm mới theo Chương hiện tại
                    </button>
                    <button
                      className="btn btn-light mt-2 ml-2"
                      data-dismiss="modal"
                    >
                      Bỏ qua
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    subjects: state.subject.subjects,
    chapters: state.chapter.chapters,
    categories: state.category.categories,
    redirect: state.question.redirect,
    image: state.question.image,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      listSubject,
      listChapter,
      listCategory,
      createQuestion,
      uploadImage,
      listQuestion,
    },
    dispatch
  );
}

let CreateQuestionContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateQuestion)
);

export default CreateQuestionContainer;
