import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Player from "react-player";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createAdmin } from "../../redux/student/action";
import { Select } from "antd";
import { isUndefined } from "util";

const { Option } = Select;

class StudentCreate extends Component {
	constructor(props) {
		super();
		this.state = {
			fullname: "",
			phone: "",
			email: "",
			school: "",
			classroom: "",
			user_group: "STUDENT",
			password: "",
			dob: "",
		};
	}

	_onChange = (e) => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		const data = {
			fullname: this.state.fullname,
			email: this.state.email,
			phone: this.state.phone,
			school: this.state.school,
			classroom: this.state.classroom,
			user_group: this.state.user_group,
			password: this.state.password,
			dob: this.state.dob,
		};
		await this.props.createAdmin(data);
		if (this.props.redirect === true) {
			await this.props.history.push("/student");
		}
	};

	handleSave = async (e) => {
		e.preventDefault();
		const data = {
			fullname: this.state.fullname,
			email: this.state.email,
			phone: this.state.phone,
			school: this.state.school,
			classroom: this.state.classroom,
			user_group: this.state.user_group,
			password: this.state.password,
			dob: this.state.dob,
		};
		await this.props.createAdmin(data);
		if (this.props.redirect === true) {
			await this.props.history.push("/student");
		}
	};

	render() {
		return (
			<div>
				<div className='page-hero page-container' id='page-hero'>
					<div className='padding d-flex'>
						<div className='page-title'>
							<h2 className='text-md text-highlight'>
								Thêm mới học sinh
							</h2>
						</div>
						<div className='flex' />
						<div>
							<Link
								to={"/student"}
								className='btn btn-sm text-white btn-primary'
							>
								<span className='d-none d-sm-inline mx-1'>
									Quay lại
								</span>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width={16}
									height={16}
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									strokeWidth={2}
									strokeLinecap='round'
									strokeLinejoin='round'
									className='feather feather-arrow-right'
								>
									<line x1={5} y1={12} x2={19} y2={12} />
									<polyline points='12 5 19 12 12 19' />
								</svg>
							</Link>
						</div>
					</div>
				</div>

				<div className='page-content page-container' id='page-content'>
					<div className='padding'>
						<div className='row'>
							<div className='col-md-10'>
								<div className='card'>
									<div className='card-header'>
										<strong>Thêm mới học sinh</strong>
									</div>
									<div className='card-body'>
										<div className='form-group row'>
											<label className='col-sm-4 col-form-label'>
												Họ và tên
											</label>
											<div className='col-sm-8'>
												<input
													type='text'
													className='form-control'
													name='fullname'
													onChange={this._onChange}
													value={this.state.fullname}
												/>
											</div>
										</div>
										<div className='form-group row'>
											<label className='col-sm-4 col-form-label'>
												Số điện thoại
											</label>
											<div className='col-sm-8'>
												<input
													type='text'
													className='form-control'
													name='phone'
													onChange={this._onChange}
													value={this.state.phone}
												/>
											</div>
										</div>
										<div className='form-group row'>
											<label className='col-sm-4 col-form-label'>
												Ngày sinh
											</label>
											<div className='col-sm-8'>
												<input
													type='date'
													className='form-control'
													name='dob'
													onChange={this._onChange}
													value={this.state.dob}
												/>
											</div>
										</div>
										<div className='form-group row'>
											<label className='col-sm-4 col-form-label'>
												Email
											</label>
											<div className='col-sm-8'>
												<input
													type='email'
													className='form-control'
													name='email'
													onChange={this._onChange}
													value={this.state.email}
												/>
											</div>
										</div>
										<div className='form-group row'>
											<label className='col-sm-4 col-form-label'>
												Trường
											</label>
											<div className='col-sm-8'>
												<input
													type='text'
													className='form-control'
													name='school'
													onChange={this._onChange}
													value={this.state.school}
												/>
											</div>
										</div>
										<div className='form-group row'>
											<label className='col-sm-4 col-form-label'>
												Lớp
											</label>
											<div className='col-sm-8'>
												<input
													type='text'
													className='form-control'
													name='classroom'
													onChange={this._onChange}
													value={this.state.classroom}
												/>
											</div>
										</div>
										<div className='form-group row'>
											<label className='col-sm-4 col-form-label'>
												Mật khẩu
											</label>
											<div className='col-sm-8'>
												<input
													type='text'
													className='form-control'
													name='password'
													onChange={this._onChange}
													value={this.state.password}
												/>
											</div>
										</div>
										<div className='form-group row'>
											<div className='col-sm-12 text-right'>
												<button
													className='btn btn-primary mt-2'
													onClick={this.handleSubmit}
												>
													Lưu
												</button>
												<button
													className='btn btn-primary mt-2 ml-2'
													onClick={this.handleSave}
												>
													Lưu &amp; Thêm mới
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		redirect: state.student.redirect,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ createAdmin }, dispatch);
}

let ClassCreateContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(StudentCreate)
);

export default ClassCreateContainer;
