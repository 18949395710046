import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { listSubject } from '../../redux/subject/action';
import { createClassroomGroup } from '../../redux/classroomgroup/action';

class ClassroomGroupCreate extends Component {
    constructor(props) {
        super();
        this.state = {
            name: '',
            subject_id: '',
            status: true,
            files: [],
            banner: [],
            content: '',
            ordering: 0,
        };
    }

    async componentDidMount() {
        const data = {
            limit: 999,
            is_delete: false,
        };
        await this.props.listSubject(data);
    }

    _onChange = async e => {
        var name = e.target.name;
        let value = e.target.value;
        if (name === 'files') {
            value = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = () => {
                    resolve(reader.result);
                }
                reader.onerror = error => reject(error);
            });
            value = [value];
        }

        if (name === 'banner') {
            value = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = () => {
                    resolve(reader.result);
                }
                reader.onerror = error => reject(error);
            });
            value = [value];
        }
        this.setState({
            [name]: value,
        });
    };


    handleSubmit = async (type) => {
        const data = {
            name: this.state.name,
            subject_id: this.state.subject_id,
            content: this.state.content,
            status: this.state.status,
            banner: this.state.banner,
            files: this.state.files,
            ordering: this.state.ordering,
        };

        if (type === 0) {
            await this.props.createClassroomGroup(data);
            if (this.props.redirect === true) {
                await this.props.history.push('/classroom-group');
            }
        } else {

            await this.props.createClassroomGroup(data);
        }
    };


    fetchRows() {
        if (this.props.subjects instanceof Array) {
            return this.props.subjects.map((obj, i) => {
                return <option value={obj._id}>{obj.name}</option>;
            });
        }
    }

    render() {
        return (
            <div>
                <div className="page-hero page-container" id="page-hero">
                    <div className="padding d-flex">
                        <div className="page-title">
                            <h2 className="text-md text-highlight">Thêm mới cấp độ</h2>
                        </div>
                        <div className="flex" />
                        <div>
                            <Link
                                to={'/classroom-group'}
                                className="btn btn-sm text-white btn-primary">
                                <span className="d-none d-sm-inline mx-1">
                                    Quay lại
								</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-right">
                                    <line x1={5} y1={12} x2={19} y2={12} />
                                    <polyline points="12 5 19 12 12 19" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="page-content page-container" id="page-content">
                    <div className="padding">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="card">
                                    <div className="card-header">
                                        <strong>Thêm  mới Danh mục lớp</strong>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <div className="col-sm-6">
                                                <label className="col-form-label">
                                                    Tên
                                                </label>
                                                <div >
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        onChange={this._onChange}
                                                        value={this.state.name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <label className=" col-form-label">
                                                    Môn học
                                                    </label>
                                                <div className="">
                                                    <select
                                                        className="custom-select"
                                                        value={
                                                            this.state.subject_id
                                                        }
                                                        name="subject_id"
                                                        onChange={this._onChange}>
                                                        <option value="">
                                                            -- Chọn môn học --
                                                        </option>
                                                        {this.fetchRows()}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="col-form-label">
                                                    Thứ tự
                                                </label>
                                                <div >
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="ordering"
                                                        onChange={this._onChange}
                                                        value={this.state.ordering}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6">
                                                <label className="col-sm-4 col-form-label">
                                                    Hình ảnh
                                                </label>
                                                <div className="col-sm-4">
                                                    <input type="file" onChange={this._onChange} className="form-control-file" name="files" />
                                                </div>
                                                
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="col-sm-4 col-form-label">
                                                    Banner
                                                </label>
                                                <div className="col-sm-4">
                                                    <input type="file" onChange={this._onChange} className="form-control-file" name="banner" />
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <label className=" col-form-label">
                                                    Trạng thái
                                                </label>
                                                <div>
                                                    <div className="form-check float-left">
                                                        <input className="form-check-input" type="radio" name="status" value="true" id="gridRadios1" onChange={this._onChange} defaultValue="option1" defaultChecked />
                                                        <label className="form-check-label" htmlFor="gridRadios1">
                                                            Hiển thị
                                                        </label>
                                                    </div>
                                                    <div className="form-check float-left ml-4">
                                                        <input className="form-check-input" type="radio" name="status" value="false" id="gridRadios2" onChange={this._onChange} defaultValue="option2" />
                                                        <label className="form-check-label" htmlFor="gridRadios2">
                                                            Ẩn
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12 text-right">
                                                <button
                                                    className="btn btn-primary mt-2"
                                                    onClick={() => this.handleSubmit(0)}>
                                                    Lưu
                                            </button>
                                                <button
                                                    className="btn btn-primary mt-2 ml-2"
                                                    onClick={() => this.handleSubmit(1)}>
                                                    Lưu & Thêm mới
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        subjects: state.subject.subjects,
        redirect: true,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ listSubject, createClassroomGroup }, dispatch);
}

let ChapterCreateContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ClassroomGroupCreate),
);

export default ChapterCreateContainer;
