import * as ActionTypes from './type';

const initState = {
    adultEvals: [],
    review: null,
    total: 0,
    page: 1,
    limit: 20,
    ids: [],
    checkAll: false,
    redirect: false
}

const reducer = (state = initState, action) => {
    switch(action.type) {

        case ActionTypes.LIST_MAGAZINE:
            return {
                ...state,
                magazines: action.magazines,
                total: action.total,
                limit: action.limit

            }
        case 'PAGING':
            return {
                ...state,
                page: action.page
            }
        case ActionTypes.SHOW_MAGAZINE:
            return {
                ...state,
                magazine: action.magazine
            }
        case ActionTypes.CREATE_MAGAZINE:
            var { magazine, redirect } = action;
            return {
                ...state,
                magazine,
                redirect,
            }
        case ActionTypes.UPDATE_MAGAZINE:
            return {
                ...state,
                redirect: action.redirect
            }
        case ActionTypes.DATA_REMOVE_MAGAZINE:
            return {
                ...state,
                dataRemoveMagazine: action.dataRemoveMagazine
            }
        case ActionTypes.DELETE_MAGAZINE:
            return {
                ...state
            }

        default:
            return {
                ...state
            }
    }
}

export default reducer;