import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showAdultEvaluation, updateAdultEvaluation } from './../../redux/adultEvaluation/action';

class ReviewEdit extends Component {
    constructor(props) {
        super();
        this.state = {
            name: '',
            description: '',
            content: '',
            files: '',
            status: false
        };
    }

    async componentDidMount() {
        await this.props.showAdultEvaluation(this.props.match.params.id);
        if (this.props.adultEval) {
            var { name, description, content, status } = this.props.adultEval;
            await this.setState({
                name,
                description,
                content,
                files: this.props.adultEval.image,
                status,
            })
        }
    }

    _onChange = async e => {
        var name = e.target.name;
        var value = e.target.value;
        if (name === 'files') {
            value = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = () => {
                    resolve(reader.result);
                }
                reader.onerror = error => reject(error);
            });
            value = [value];
        }

        await this.setState({
            [name]: value
        });
    };


    handleSubmit = e => {
        e.preventDefault();

        var { name, description, content, files, status } = this.state;
        var data = {
            id: this.props.match.params.id,
            name,
            description,
            content,
            files,
            status,
        };

        console.log(data);
        this.props.updateAdultEvaluation(data);
    };

    render() {
        return (
            <div>
                <div className="page-hero page-container" id="page-hero">
                    <div className="padding d-flex">
                        <div className="page-title">
                            <h2 className="text-md text-highlight">Cập nhật đánh giá của phụ huynh</h2>
                        </div>
                        <div className="flex" />
                        <div>
                            <Link
                                to={'/adult-evaluation'}
                                className="btn btn-sm text-white btn-primary">
                                <span className="d-none d-sm-inline mx-1">
                                    Quay lại
                                </span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-right">
                                    <line x1={5} y1={12} x2={19} y2={12} />
                                    <polyline points="12 5 19 12 12 19" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="page-content page-container" id="page-content">
                    <div className="padding">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="card">
                                    <div className="card-header">
                                        <strong>Cập nhật đánh giá của phụ huynh</strong>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <div className="col-sm-6">
                                                <label className="col-form-label">
                                                    Tên
                                                </label>
                                                <div >
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        onChange={this._onChange}
                                                        value={this.state.name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <label className=" col-form-label">
                                                    Mô tả
                                                </label>
                                                <div>
                                                    <textarea
                                                        className="form-control"
                                                        name="description"
                                                        onChange={this._onChange}
                                                        value={this.state.description}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <label className=" col-form-label">
                                                    Nội dung
                                                </label>
                                                <div>
                                                    <textarea
                                                        className="form-control"
                                                        name="content"
                                                        onChange={this._onChange}
                                                        value={this.state.content}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="col-sm-4 col-form-label">
                                                    Hình ảnh
                                                </label>
                                                <div className="col-sm-4">
                                                    <input type="file" onChange={this._onChange} className="form-control-file" name="files" />
                                                </div>
                                                <div className="col-sm-4 d-flex">
                                                    <img alt="" src={
                                                        this.props.adultEval
                                                            ?
                                                            this.props.adultEval.image
                                                            : ''
                                                    }
                                                        style={{ width: '200px' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6">
                                                <label className=" col-form-label">
                                                    Trạng thái
                                                </label>
                                                <div>
                                                    <div className="form-check float-left">
                                                        <input checked={this.state.status === true || this.state.status === "true"} className="form-check-input"
                                                            type="radio" name="status" value="true" id="gridRadios1"
                                                            onChange={this._onChange} />
                                                        <label className="form-check-label" htmlFor="gridRadios1">
                                                            Hiển thị
                                                        </label>
                                                    </div>
                                                    <div className="form-check float-left ml-4">
                                                        <input checked={this.state.status === false || this.state.status === "false"}
                                                            className="form-check-input" type="radio" name="status" value="false" id="gridRadios2" onChange={this._onChange} />
                                                        <label className="form-check-label" htmlFor="gridRadios2">
                                                            Ẩn
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12 text-right">
                                                <button
                                                    className="btn btn-primary mt-2"
                                                    onClick={this.handleSubmit}>
                                                    Cập nhật
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        adultEval: state.adultEvals.adultEval,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showAdultEvaluation, updateAdultEvaluation }, dispatch);
}

let ContainerEdit = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ReviewEdit),
);

export default ContainerEdit;
