import React, { Component } from "react";
import Moment from "moment";
import { notification } from "antd";
import Pagination from "react-js-pagination";
import {
	listClassroom,
	checkInputItem,
	deleteClassroom,
	checkAll,
	addDataRemoveClass,
	updateMetaData
} from "../../redux/classroom/action";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isUndefined } from "util";

class Row extends Component {
	constructor(props) {
		super();
		this.state = {
			check: false,
			show_on_cart: false,
			status: false,
			is_featured: false,
			ordering: 0,
		};
	}

	componentDidMount() {
		this.setState({
			show_on_cart: this.props.obj.show_on_cart ? this.props.obj.show_on_cart : false,
			is_featured: this.props.obj.is_featured ? this.props.obj.is_featured : false,
			ordering: this.props.obj.ordering,
			status: this.props.obj.status
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.check !== nextProps.check) {
			this.setState({
				check: nextProps.check,
			});
		}
	}

	handleCheck = (e) => {
		this.props.onDeleteOne(true);
		this.props.addDataRemoveClass({
			ids: this.props.obj._id
		})
	};
	handleCheckBox = (e) => {
		if (e.target.checked) {
			this.props.handleCheckedIds(this.props.obj._id, 'add');
			this.setState({
				check: e.target.checked
			});
		} else {
			this.props.handleCheckedIds(this.props.obj._id, 'remove');
			this.setState({
				check: e.target.checked
			});
		}
	}

	handleChangeOrdering = async e => {
		var name = e.target.name;
		var value = e.target.value;
		await this.setState({
			[name]: value,
		});
		const data = {
			id: this.props.obj._id,
			ordering: this.state.ordering,
		};

		await this.props.updateMetaData(data);
	};

	handleChangeStatus = async e => {
		e.preventDefault();
		var name = e.target.name;
		var checke = e.target.checked;

		await this.setState({
			[name]: checke,
		});

		const data = {
			id: this.props.obj._id,
			status: this.state.status,
		};

		await this.props.updateMetaData(data);
	};

	handleChangeFeatured= async e => {
		e.preventDefault();
		var name = e.target.name;
		var checke = e.target.checked;

		await this.setState({
			[name]: checke,
		});

		const data = {
			id: this.props.obj._id,
			is_featured: this.state.is_featured
		};
		await this.props.updateMetaData(data);
	};

	render() {
		if (this.props.obj) {
			return (
				<tr className='v-middle' data-id={17}>
					<td>
						<label className='ui-check m-0'>
							<input
								type='checkbox'
								name='id'
								className="checkInputItem"
								value={this.props.obj._id}
								onChange={this.handleCheckBox}
							/>{" "}
							<i />
						</label>
					</td>
					<td>
						<span className='item-amount d-none d-sm-block text-sm'>
							<Link
								className='item-author text-color'
								to={"/classroom/" + this.props.obj._id + "/edit"}
							>{!isUndefined(this.props.obj.code) &&
								this.props.obj.code}
							</Link>
						</span>
					</td>
					<td className='flex'>
						<Link
							className='item-author text-color'
							to={"/classroom/" + this.props.obj._id + "/edit"}
						>
							{this.props.obj.name}
						</Link>
					</td>
					<td>
						<span className='item-amount d-none d-sm-block text-sm'>
							{this.props.obj.subject.name}
						</span>
					</td>
					<td>
						<span className='item-amount d-none d-sm-block text-sm'>
							{this.props.obj.teacher}
						</span>
					</td>
					<td>
						<span className='item-amount d-none d-sm-block text-sm'>
							{this.props.obj.room}
						</span>
					</td>
					<td className="text-left">
						<span className="item-amount d-none d-sm-block text-sm">
							<label className="ui-switch ui-switch-md info m-t-xs">
								<input
									type="checkbox"
									name="status"
									value={this.props.obj._id}
									checked={this.state.status === true ? 'checked' : ''}
									onChange={this.handleChangeStatus}
								/>{' '}
								<i />
							</label>
						</span>
					</td>
					<td className="text-left">
						<span className="item-amount d-none d-sm-block text-sm">
							<label className="ui-switch ui-switch-md info m-t-xs">
								<input
									type="checkbox"
									name="is_featured"
									value={this.props.obj._id}
									checked={this.state.is_featured === true ? 'checked' : ''}
									onChange={this.handleChangeFeatured}
								/>{' '}
								<i />
							</label>
						</span>
					</td>
					<td className="text-left">
						<input type="number" class="form-control" name="ordering" value={this.state.ordering} onChange={this.handleChangeOrdering} />
					</td>
					<td>
						<span className='item-amount d-none d-sm-block text-sm'>
							{this.props.obj.updated_at &&
								Moment(this.props.obj.updated_at).format(
									"DD/MM/YYYY HH:mm:ss"
								)}
						</span>
					</td>
					<td>
						<div className='item-action dropdown'>
							<a
								href='/'
								data-toggle='dropdown'
								className='text-muted'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width={16}
									height={16}
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									strokeWidth={2}
									strokeLinecap='round'
									strokeLinejoin='round'
									className='feather feather-more-vertical'
								>
									<circle cx={12} cy={12} r={1} />
									<circle cx={12} cy={5} r={1} />
									<circle cx={12} cy={19} r={1} />
								</svg>
							</a>
							<div
								className='dropdown-menu dropdown-menu-right bg-white'
								role='menu'
							>
								<Link
									className='dropdown-item'
									style={{ cursor: "pointer" }}
									to={
										"/classroom/" +
										this.props.obj._id +
										"/edit"
									}
								>
									Sửa
								</Link>
								<Link
									className='dropdown-item'
									style={{ cursor: "pointer" }}
									to={
										"/classroom/" +
										this.props.obj._id +
										"/code"
									}
								>
									Mã truy cập
								</Link>
								<Link
									className='dropdown-item'
									style={{ cursor: "pointer" }}
									to={
										"/classroom/" +
										this.props.obj._id +
										"/report"
									}
								>
									Báo cáo
								</Link>
								<Link
									className='dropdown-item'
									style={{ cursor: "pointer" }}
									to={
										"/classroom/" +
										this.props.obj._id +
										"/member"
									}
								>
									Thành viên
								</Link>
								<div className='dropdown-divider' />
								<button
									onClick={this.handleCheck}
									className='dropdown-item trash'
									data-toggle='modal'
									data-target='#delete-classroom'
									data-toggle-class='fade-down'
									data-toggle-class-target='.animate'
								>
									Xóa
								</button>
							</div>
						</div>
					</td>
				</tr>
			);
		}
	}
}

class Classroom extends Component {
	constructor(props) {
		super();
		this.state = {
			data: [],
			limit: 20,
			ids: [],
			checkAll: false,
			searchItem: []
		};
	}

	fetchRows() {
		if (this.props.classrooms instanceof Array) {
			return this.props.classrooms.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						checkInputItem={this.props.checkInputItem}
						check={this.props.check}
						handleCheckedIds={this.handleCheckedIds}
						onDeleteOne={this.onDeleteOne}
						addDataRemoveClass={this.props.addDataRemoveClass}
						updateMetaData={this.props.updateMetaData}
					/>
				);
			});
		}
	}

	onChange = (e) => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};

	handleCheckedIds = (id, type = '') => {
		var _ids = this.state.ids;
		if (type === 'add') {
			if (_ids.indexOf(id) < 0) {
				_ids.push(id);
			}
		}
		if (type === 'remove') {
			let index = _ids.indexOf(id);
			if (index > -1) {
				_ids.splice(index, 1);
			}
		}

		this.setState({
			ids: _ids
		})
	}

	onDeleteOne = (onResetIds) => {
		if (onResetIds) {
			this.setState({
				ids: []
			})
		}
	}

	getData = (pageNumber = 1) => {
		const params = new URLSearchParams();
		params.append('page', pageNumber);
		params.append('limit', this.state.limit);
		const data = {
			page: pageNumber,
			limit: this.state.limit,
		};
		if (this.state.keyword != null) {
			data["keyword"] = this.state.keyword;
			params.append('keyword', this.state.keyword);
		}
		this.props.history.push('/classroom?' + params.toString());
		return data;
	};

	async componentDidMount() {
		await this.props.listClassroom(this.getData());
		if (this.props.limit) {
			await this.setState({
				limit: this.props.limit,
				ids: this.props.ids,
				checkAll: false,
			});
		}
	}

	onSubmit = (e) => {
		e.preventDefault();
		this.props.listClassroom(this.getData());
	};

	handleChangePage = async (pageNumber) => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		await this.props.listClassroom(this.getData(pageNumber));
	};

	handleDelete = async () => {
		let inputs = document.querySelectorAll('.checkInputItem');
		let data = this.props.dataRemoveClass;

		if (this.state.ids && this.state.ids.length > 0) {
			data = {
				ids: this.state.ids
			};
		}

		await this.props.deleteClassroom(data);
		await this.props.listClassroom(this.getData());

		for (let i = 0; i < inputs.length; i++) {
			inputs[i].checked = false;
		}
	};

	handleChange = async (e) => {
		var name = e.target.name;
		var value = e.target.value;
		await this.setState({
			[name]: value,
		});
		await this.props.listClassroom(this.getData());
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.checkAll !== nextProps.check) {
			this.setState({
				checkAll: nextProps.check,
			});
		}
	}

	handleCheckAll = async (e) => {
		// if (e.target.checked) {
		// 	this.props.checkAll(true);
		// 	this.setState({
		// 		checkAll: e.target.checked,
		// 	});
		// } else {
		// 	this.props.checkAll(false);
		// 	this.setState({
		// 		checkAll: e.target.checked,
		// 	});
		// }
		var inputs = document.querySelectorAll('.checkInputItem');
		var flag = false;

		if (e.target.checked) {
			flag = true;
		}

		let _ids = [];
		for (let i = 0; i < inputs.length; i++) {
			inputs[i].checked = flag;
			if (flag) {
				_ids.push(inputs[i].value);
			} else {
				_ids = [];
			}
		}

		await this.setState({
			ids: _ids
		})
	};

	handleDeleteAll = () => {
		var data = this.state.ids;

		if (data.length === 0) {
			notification.warning({
				message: 'Chưa chọn mục nào !',
				placement: 'topRight',
				top: 50,
				duration: 3,
			});
		}
	}

	render() {
		let displayFrom =
			this.props.page === 1
				? 1
				: (parseInt(this.props.page) - 1) * this.props.limit;
		let displayTo =
			this.props.page === 1
				? this.props.limit
				: displayFrom + this.props.limit;
		displayTo = displayTo > this.props.total ? this.props.total : displayTo;
		return (
			<div>
				<div className='page-hero page-container' id='page-hero'>
					<div className='padding d-flex'>
						<div className='page-title'>
							<h2 className='text-md text-highlight'>Lớp học</h2>
							<small className='text-muted'>
								Quản lý danh sách lớp học
							</small>
						</div>
						<div className='flex' />
						<div>
							<Link
								to={`/classroom/create`}
								className='btn btn-sm btn-primary text-muted'
							>
								<span className='d-none d-sm-inline mx-1'>
									Thêm mới
								</span>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width={16}
									height={16}
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									strokeWidth={2}
									strokeLinecap='round'
									strokeLinejoin='round'
									className='feather feather-arrow-right'
								>
									<line x1={5} y1={12} x2={19} y2={12} />
									<polyline points='12 5 19 12 12 19' />
								</svg>
							</Link>
						</div>
					</div>
				</div>
				<div className='page-content page-container' id='page-content'>
					<div className='padding'>
						<div className='mb-5'>
							<div className='toolbar'>
								<div className="btn-group">
									{this.state.ids.length !== 0 ? (
										<button
											className="btn btn-icon"
											data-toggle="modal"
											data-target="#delete-classroom"
											data-toggle-class="fade-down"
											data-toggle-class-target=".animate"
											title="Trash"
											id="btn-trash">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width={16}
												height={16}
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												strokeWidth={2}
												strokeLinecap="round"
												strokeLinejoin="round"
												className="feather feather-trash text-muted">
												<polyline points="3 6 5 6 21 6" />
												<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
											</svg>
										</button>
									) : (
										<button
											className="btn btn-icon"
											onClick={this.handleDeleteAll}
											title="Trash"
											id="btn-trash">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width={16}
												height={16}
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												strokeWidth={2}
												strokeLinecap="round"
												strokeLinejoin="round"
												className="feather feather-trash text-muted">
												<polyline points="3 6 5 6 21 6" />
												<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
											</svg>
										</button>
									)}
								</div>
								<form className='flex' onSubmit={this.onSubmit}>
									<div className='input-group'>
										<input
											type='text'
											className='form-control form-control-theme keyword-custom'
											placeholder='Nhập từ khoá tìm kiếm...'
											onChange={this.onChange}
											name='keyword'
										/>{" "}
										<span className='input-group-append'>
											<button
												className='btn btn-white btn-sm'
												type='submit'
											>
												<span className='d-flex text-muted'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width={16}
														height={16}
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														strokeWidth={2}
														strokeLinecap='round'
														strokeLinejoin='round'
														className='feather feather-search'
													>
														<circle
															cx={11}
															cy={11}
															r={8}
														/>
														<line
															x1={21}
															y1={21}
															x2='16.65'
															y2='16.65'
														/>
													</svg>
												</span>
											</button>
										</span>
									</div>
								</form>
							</div>

							<div className='row'>
								<div className='col-sm-12'>
									<table className='table table-theme table-row v-middle'>
										<thead className='text-muted'>
											<tr>
												<th width="10px">
													<label className="ui-check m-0">
														<input
															type="checkbox"
															name="id"
															onChange={this.handleCheckAll}
														/>{' '}
														<i />
													</label>
												</th>
												<th>Mã lớp</th>
												<th>Tên lớp</th>
												<th>Môn học</th>
												<th>Giáo viên</th>
												<th>Phòng học</th>
												<th>Trạng thái</th>
												<th>
													Nổi bật
												</th>
												<th>Thứ tự</th>
												<th width='150px'>
													Thời gian cập nhật
												</th>
												<th width='50px' />
											</tr>
										</thead>
										<tbody>{this.fetchRows()}</tbody>
									</table>
								</div>
							</div>

							<div className='row listing-footer'>
								<div className='col-sm-2'>
									<select
										className='custom-select w-96'
										name='limit'
										value={this.state.limit}
										onChange={this.handleChange}
									>
										<option value='20'>20</option>
										<option value='50'>50</option>
										<option value='100'>100</option>
										<option value='-1'>ALL</option>
									</select>
								</div>
								<div className='col-sm-5 showing-text'>
									{" "}
									Hiển thị từ <b>{displayFrom}</b> đến{" "}
									<b>{displayTo}</b> trong tổng số{" "}
									<b>{this.props.total}</b>
								</div>
								{this.props.total !== 0 ? (
									<div className='col-sm-5 text-right'>
										<Pagination
											activePage={this.props.page}
											itemsCountPerPage={this.props.limit}
											totalItemsCount={this.props.total}
											pageRangeDisplayed={10}
											onChange={this.handleChangePage}
										/>
									</div>
								) : (
									<div className=''>Không có bản ghi nào</div>
								)}
							</div>

							<div
								id='delete-classroom'
								className='modal fade'
								data-backdrop='true'
								style={{ display: "none" }}
								aria-hidden='true'
							>
								<div
									className='modal-dialog animate fade-down'
									data-class='fade-down'
								>
									<div className='modal-content'>
										<div className='modal-header'>
											<div className='modal-title text-md'>
												Thông báo
											</div>
											<button
												className='close'
												data-dismiss='modal'
											>
												×
											</button>
										</div>
										<div className='modal-body'>
											<div className='p-4 text-center'>
												<p>
													Bạn chắc chắn muốn xóa bản
													ghi này chứ?
												</p>
											</div>
										</div>
										<div className='modal-footer'>
											<button
												type='button'
												className='btn btn-light'
												data-dismiss='modal'
											>
												Đóng
											</button>
											<button
												type='button'
												onClick={this.handleDelete}
												className='btn btn-danger'
												data-dismiss='modal'
											>
												Xoá
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		classrooms: state.classroom.classrooms,
		classroom: state.classroom ? state.classroom.classroom : {},
		limit: state.classroom.limit,
		page: state.classroom.page,
		total: state.classroom.total,
		ids: state.classroom.ids,
		check: state.classroom.checkAll,
		dataRemoveClass: state.classroom.dataRemoveClass
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{ listClassroom, deleteClassroom, checkInputItem, checkAll, addDataRemoveClass, updateMetaData },
		dispatch
	);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Classroom)
);
