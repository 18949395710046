import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { listSubject } from "../../redux/subject/action";
import { createClassroom } from "../../redux/classroom/action";
import { listClassroomGroup } from "../../redux/classroomgroup/action";
import { Radio } from "antd";
import { listStudent } from "../../redux/student/action";
import { uploadImage } from "../../redux/category/action";
import { Editor } from "@tinymce/tinymce-react";
import { notification, DatePicker, LocaleProvider } from "antd";
import enUS from 'antd/lib/locale-provider/en_US';
import moment from "moment";

import "antd/dist/antd.css";

class ClassroomCreate extends Component {
  constructor(props) {
    super();
    this.state = {
      code: "",
      name: "",
      subject_id: "",
      subjectID: "",
      teacher_id: "",
      group_id: "",
      teacher: "",
      room: "",
      note: "",
      description: "",
      video_intro: "",
      content: "",
      files: [],
      next_classrooms: null,
      hp_day: "",
      hp_1month_day: "",
      hp_3month_day: "",
      hp_6month_day: "",
      hp_12month_day: "",
      is_cadup: false,
      is_online: false,
      is_featured: false,
      status: false,
      extra_number_student: 0,
      link_fb_page: "",
      link_fb_group: "",
      promotion: {
        // type: '',
        // hour: null,
        from_date: null,
        to_date: null
      },
      ordering: 0
    };
  }

  async componentDidMount() {
    const data = {
      limit: 999,
      is_delete: false,
    };
    await this.props.listSubject(data);
  }

  _onChange = async (e) => {
    var name = e.target.name;
    var value = e.target.value;
    if (name === "files") {
      value = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
      });
      value = [value];
    }
    this.setState({
      [name]: value,
    });

    if (name === "subject_id") {
      let params = {};

      if (value) {
        params = {
          subject_id: value,
          limit: 100,
        };
        await this.props.listClassroomGroup(params);

        params = {
          user_group: "TEACHER",
          subject_id: value,
          limit: 100,
        };
        await this.props.listStudent(params);
      }
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      code: this.state.code,
      name: this.state.name,
      subject_id: this.state.subject_id,
      group_id: this.state.group_id,
      teacher_id: this.state.teacher_id,
      teacher: this.state.teacher,
      room: this.state.room,
      note: this.state.note,
      files: this.state.files,
      hp_day: this.state.hp_day,
      hp_1month_day: this.state.hp_1month_day,
      hp_3month_day: this.state.hp_3month_day,
      hp_6month_day: this.state.hp_6month_day,
      hp_12month_day: this.state.hp_12month_day,
      is_cadup: this.state.is_cadup,
      is_online: this.state.is_online,
      is_featured: this.state.is_featured,
      video_intro: this.state.video_intro,
      status: this.state.status,
      extra_number_student: this.state.extra_number_student,
      ordering: this.state.ordering
    };
    data.description = this.state.description.toString();
    data.content = this.state.content.toString();

    await this.props.createClassroom(data);
    if (this.props.redirect === true) {
      await this.props.history.push("/classroom");
    }
  };

  fetchTeacherRows() {
    if (this.props.students instanceof Array) {
      return this.props.students.map((obj, i) => {
        return (
          <option value={obj._id} key={obj._id.toString()}>
            {obj.fullname}
          </option>
        );
      });
    }
  }

  handleSave = async (e) => {
    let { promotion } = this.state;
    e.preventDefault();
    const data = {
      code: this.state.code,
      name: this.state.name,
      subject_id: this.state.subject_id,
      group_id: this.state.group_id,
      teacher_id: this.state.teacher_id,
      teacher: this.state.teacher,
      room: this.state.room,
      note: this.state.note,
      hp_day: this.state.hp_day,
      files: this.state.files,
      hp_1month_day: this.state.hp_1month_day,
      hp_3month_day: this.state.hp_3month_day,
      hp_6month_day: this.state.hp_6month_day,
      hp_12month_day: this.state.hp_12month_day,
      is_cadup: this.state.is_cadup,
      is_online: this.state.is_online,
      is_featured: this.state.is_featured,
      video_intro: this.state.video_intro,
      status: this.state.status,
      extra_number_student: this.state.extra_number_student,
      link_fb_group: this.state.link_fb_group,
      link_fb_page: this.state.link_fb_page,
      ordering: this.state.ordering
      // promotion: this.state.promotion.to_date && this.state.promotion.from_date ? this.state.promotion : null
    };

    if (promotion.to_date || promotion.from_date) {
      data.promotion = promotion;
    }

    data.description = this.state.description.toString();
    data.content = this.state.content.toString();
    await this.props.createClassroom(data);
    if (this.props.redirect === true) {
      await this.props.history.push("/classroom");
    }
  };

  fetchRows() {
    if (this.props.subjects instanceof Array) {
      return this.props.subjects.map((obj, i) => {
        return (
          <option value={obj._id} key={obj._id.toString()}>
            {obj.name}
          </option>
        );
      });
    }
  }

  fetchGroupRows() {
    if (
      this.props.classroomGroups &&
      this.props.classroomGroups instanceof Array
    ) {
      return this.props.classroomGroups.map((obj, i) => {
        return (
          <option value={obj._id} key={obj._id.toString()}>
            {obj.name}
          </option>
        );
      });
    }
  }

  _handleEditorContentChange = (value, editor) => {
    this.setState({ content: value });
  };
  _handleEditorDescriptionChange = (value, editor) => {
    this.setState({ description: value });
  };

  _uploadImageCallBack = async (blobInfo, success, failure) => {
    let file = blobInfo.blob();
    const data = new FormData();
    data.append("files", file);

    await this.props.uploadImage(data);

    if (this.props.image != null) {
      success(this.props.image);
    } else {
      failure("Upload image fail");
    }
  };

  changeDateStart = (date, dateString) => {
    if (date !== null) {
      this.setState({
        promotion: {
          ...this.state.promotion,
          from_date: date.format("YYYY/MM/DD HH:mm"),
        }
      });
    }
  };

  changeDateEnd = (date, dateString) => {
    if (date !== null) {
      this.setState({
        promotion: {
          ...this.state.promotion,
          to_date: date.format("YYYY/MM/DD HH:mm"),
        }
      });
    }
  };



  render() {
    const { promotion } = this.state
    return (
      <div>
        <div className="page-hero page-container" id="page-hero">
          <div className="padding d-flex">
            <div className="page-title">
              <h2 className="text-md text-highlight">Thêm mới lớp học</h2>
            </div>
            <div className="flex" />
            <div>
              <Link
                to={"/classroom"}
                className="btn btn-sm text-white btn-primary"
              >
                <span className="d-none d-sm-inline mx-1">Quay lại</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-right"
                >
                  <line x1={5} y1={12} x2={19} y2={12} />
                  <polyline points="12 5 19 12 12 19" />
                </svg>
              </Link>
            </div>
          </div>
        </div>

        <div className="page-content page-container" id="page-content">
          <div className="padding">
            <div className="row">
              <div className="col-md-10">
                <div className="card">
                  <div className="card-header">
                    <strong>Thêm mới lớp học</strong>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">Mã lớp</label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          name="code"
                          onChange={this._onChange}
                          value={this.state.code}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">Tên lớp</label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          onChange={this._onChange}
                          value={this.state.name}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">Môn học</label>
                      <div className="col-sm-8">
                        <select
                          className="custom-select"
                          value={this.state.subject_id}
                          name="subject_id"
                          onChange={this._onChange}
                        >
                          <option value="">-- Chọn môn học --</option>
                          {this.fetchRows()}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Danh mục
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="custom-select"
                          value={this.state.group_id}
                          name="group_id"
                          onChange={this._onChange}
                        >
                          <option value="">-- Chọn danh mục --</option>
                          {this.fetchGroupRows()}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Giáo viên
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="custom-select"
                          value={this.state.teacher_id}
                          name="teacher_id"
                          onChange={this._onChange}
                        >
                          <option value="">-- Chọn giáo viên --</option>
                          {this.fetchTeacherRows()}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Số học sinh (Mở rộng)
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          name="extra_number_student"
                          onChange={this._onChange}
                          value={this.state.extra_number_student}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Phòng học
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          name="room"
                          onChange={this._onChange}
                          value={this.state.room}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Học phí/ ngày
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="number"
                          className="form-control"
                          name="hp_day"
                          onChange={this._onChange}
                          value={this.state.hp_day}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Học phí/1 ngày/ 1 tháng
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="number"
                          className="form-control"
                          name="hp_1month_day"
                          onChange={this._onChange}
                          value={this.state.hp_1month_day}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Học phí/1 ngày/ 3 tháng
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="number"
                          className="form-control"
                          name="hp_3month_day"
                          onChange={this._onChange}
                          value={this.state.hp_3month_day}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Học phí/1 ngày/ 6 tháng
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="number"
                          className="form-control"
                          name="hp_6month_day"
                          onChange={this._onChange}
                          value={this.state.hp_6month_day}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Học phí/1 ngày/ 12 tháng
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="number"
                          className="form-control"
                          name="hp_12month_day"
                          onChange={this._onChange}
                          value={this.state.hp_12month_day}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">Loại ca</label>
                      <div className="col-sm-8">
                        <Radio.Group
                          onChange={this._onChange}
                          name="is_cadup"
                          value={this.state.is_cadup}
                        >
                          <Radio value={false}>Ca đơn</Radio>
                          <Radio value={true}>Ca đúp</Radio>
                        </Radio.Group>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Hình thức học
                      </label>
                      <div className="col-sm-8">
                        <Radio.Group
                          onChange={this._onChange}
                          name="is_online"
                          value={this.state.is_online}
                        >
                          <Radio value={false}>Offline</Radio>
                          <Radio value={true}>Online</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Nổi bật
                      </label>
                      <div className="col-sm-8">
                        <Radio.Group
                          onChange={this._onChange}
                          name="is_featured"
                          value={this.state.is_featured}
                        >
                          <Radio value={false}>Mặc định</Radio>
                          <Radio value={true}>Nổi bật</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Video giới thiệu khóa học
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          name="video_intro"
                          placeholder="Ví dụ: https://www.youtube.com/watch?v=II18gmE24po"
                          onChange={this._onChange}
                          value={this.state.video_intro}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Link Page Facebook
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          name="link_fb_page"
                          onChange={this._onChange}
                          value={this.state.link_fb_page}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Link Group Facebook
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          name="link_fb_group"
                          onChange={this._onChange}
                          value={this.state.link_fb_group}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className=" col-form-label">Hình ảnh</label>
                        <div className="">
                          <input
                            onChange={this._onChange}
                            type="file"
                            className="form-control-file"
                            name="files"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">Ghi chú</label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          name="note"
                          onChange={this._onChange}
                          value={this.state.note}
                        />
                      </div>
                    </div>


                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">Áp dụng khuyến mại </label>
                      <div className="col-sm-8">
                        <DatePicker
                          format={
                            "YYYY/MM/DD HH:mm"
                          }
                          value={promotion.from_date
                            ? moment(promotion.from_date)
                            : null}
                          showTime={{ format: 'HH:mm' }}
                          placeholder="Từ ngày"
                          onChange={this.changeDateStart}
                        />
                        <DatePicker
                          format={
                            "YYYY/MM/DD HH:mm"
                          }
                          value={promotion.to_date
                            ? moment(promotion.to_date)
                            : null}
                          showTime={{ format: 'HH:mm' }}
                          placeholder="Đến ngày"
                          onChange={this.changeDateEnd}
                          className="ml-2"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">Thứ tự</label>
                      <div className="col-sm-8">
                        <input
                          type="number"
                          className="form-control"
                          name="ordering"
                          onChange={this._onChange}
                          value={this.state.ordering}
                        />
                      </div>
                    </div>



                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Mô tả ngắn
                      </label>
                      <div className="col-sm-8">
                        <Editor
                          onInit={(evt, editor) => {
                            this._handleEditorDescriptionChange(
                              this.state.description,
                              editor
                            );
                          }}
                          value={this.state.description}
                          init={{
                            height: 500,
                            menubar: false,
                            images_file_types:
                              "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                            ],
                            external_plugins: {
                              tiny_mce_wiris:
                                "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                            },
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | image | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                              "searchreplace visualblocks code fullscreen | " +
                              "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                            fontsize_formats:
                              "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            draggable_modal: true,
                            htmlAllowedTags: [".*"],
                            htmlAllowedAttrs: [".*"],
                            images_upload_handler: (
                              blobInfo,
                              success,
                              failure
                            ) =>
                              this._uploadImageCallBack(
                                blobInfo,
                                success,
                                failure
                              ),
                          }}
                          onEditorChange={this._handleEditorDescriptionChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Nội dung chi tiết
                      </label>
                      <div className="col-sm-8">
                        <Editor
                          onInit={(evt, editor) => {
                            this._handleEditorContentChange(
                              this.state.content,
                              editor
                            );
                          }}
                          value={this.state.content}
                          init={{
                            height: 500,
                            menubar: false,
                            images_file_types:
                              "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                            ],
                            external_plugins: {
                              tiny_mce_wiris:
                                "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                            },
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | image | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                              "searchreplace visualblocks code fullscreen | " +
                              "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                            fontsize_formats:
                              "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            draggable_modal: true,
                            htmlAllowedTags: [".*"],
                            htmlAllowedAttrs: [".*"],
                            images_upload_handler: (
                              blobInfo,
                              success,
                              failure
                            ) =>
                              this._uploadImageCallBack(
                                blobInfo,
                                success,
                                failure
                              ),
                          }}
                          onEditorChange={this._handleEditorContentChange}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className=" col-form-label">Trạng thái</label>
                        <div>
                          <div className="form-check float-left">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value="true"
                              id="gridRadios1"
                              onChange={this._onChange}
                              defaultValue="option1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridRadios1"
                            >
                              Hiển thị
                            </label>
                          </div>
                          <div className="form-check float-left ml-4">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value="false"
                              id="gridRadios2"
                              onChange={this._onChange}
                              defaultValue="option2"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridRadios2"
                            >
                              Ẩn
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12 text-right">
                        <button
                          className="btn btn-primary mt-2"
                          onClick={this.handleSubmit}
                        >
                          Lưu
                        </button>
                        <button
                          className="btn btn-primary mt-2 ml-2"
                          onClick={this.handleSave}
                        >
                          Lưu &amp; Thêm mới
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    subjects: state.subject.subjects,
    redirect: state.classroom.redirect,
    students: state.student.students,
    classroomGroups: state.classroomGroup.classroomGroups,
    image: state.question.image,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      listSubject,
      createClassroom,
      listStudent,
      listClassroomGroup,
      uploadImage,
    },
    dispatch
  );
}

let ClassCreateContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClassroomCreate)
);

export default ClassCreateContainer;
