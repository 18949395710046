import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { notification, DatePicker } from "antd";

import {
	listBill,
	addDelete,
	deleteBill,
	checkAll,
	listBillReport,
	resetStateBill,
} from "../../redux/bill/action";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { listSubject } from "../../redux/subject/action";
import { listAccountant } from "../../redux/student/action";

class Row extends Component {
	constructor(props) {
		super();
		this.state = {};
	}

	render() {
		return (
			<tr className='v-middle' data-id={17}>
				<td>{this.props.index + 1}</td>

				<td className='text-left'>{this.props.obj._id.name}</td>

				<td className='text-right'>
					<span className='item-amount d-none d-sm-block text-sm'>
						{this.props.obj.total.toLocaleString("en-EN", {
							minimumFractionDigits: 0,
						})}
					</span>
				</td>
			</tr>
		);
	}
}

class BillReport extends Component {
	constructor(props) {
		super();
		this.state = {
			data: [],
			limit: "",
			ids: [],
			checkAll: false,
			subject_id: "",
			creator_id: "",
			from_date: "",
			to_date: "",
			by_type: 'BY_SUBJECT',
			payment_method: '',
			show_subject: true,
			show_creator: false,
			headers: [
				{ label: "STT", key: "stt" },
				{ label: "Lớp học", key: "_id.name" },
				{ label: "Doanh thu", key: "total" },
			],
			billReports: [],
		};
	}

	UNSAFE_componentWillReceiveProps = async (nextProps) => {
		if (nextProps.billReports.length > 0) {
			let billReports = nextProps.billReports.map((ele, i) => {
				return Object.assign(
					{},
					{
						...ele,
						stt: i + 1,
					}
				);
			});
			await this.setState({
				billReports,
			});
		}
	};

	fetchRows() {
		if (this.props.billReports instanceof Array) {
			return this.props.billReports.map((object, i) => {
				return <Row obj={object} key={object._id} index={i} />;
			});
		}
	}

	onChange = async (e) => {
		var name = e.target.name;
		var value = e.target.value;
		if (name === 'by_type') {
			if (value === 'BY_SUBJECT') {
				this.setState({
					show_subject: true,
					creator_id: "",			
					show_creator: false
				});				
			}

			if (value === 'BY_ACCOUNTANT') {
				this.setState({
					show_subject: false,
					subject_id: "",
					show_creator: true
				});				
			}
		}

		this.setState({
			[name]: value,
		});

		this.setState({
			billReports: []
		})
	};

	getData = (pageNumber = 1) => {
		const data = {};
		if (this.state.keyword != null) {
			data["keyword"] = this.state.keyword;
		}
		if (this.state.subject_id !== "") {
			data["subject_id"] = this.state.subject_id;
		}
		if (this.state.creator_id !== "") {
			data["creator_id"] = this.state.creator_id;
		}

		if (this.state.payment_method !== "") {
			data["payment_method"] = this.state.payment_method;
		}

		if (this.state.from_date != null) {
			data["from_date"] = this.state.from_date;
		}
		if (this.state.to_date != null) {
			data["to_date"] = this.state.to_date;
		}
		data['type'] = this.state.by_type;		
		return data;
	};

	async componentDidMount() {
		await this.props.listSubject({ limit: 999 });
		await this.props.listAccountant({ limit: 999 });
	}

	onSubmit = async (e) => {
		e.preventDefault();
		if (this.state.from_date === "") {
			this.fromDateInput.focus();
			notification.warning({
				message: "Vui lòng chọn thời gian lọc!",
				placement: "topRight",
				top: 50,
				duration: 3,
			});
		} else if (this.state.to_date === "") {
			this.toDateInput.focus();
			notification.warning({
				message: "Vui lòng chọn thời gian lọc!",
				placement: "topRight",
				top: 50,
				duration: 3,
			});
		} else {
			await this.props.listBillReport(this.getData());
		}
	};

	UNSAFE_componentWillReceiveProps(nextProps) { }

	changeDateStart = (date, dateString) => {
		if (date !== null) {
			this.setState({
				from_date: date.format("YYYY/MM/DD"),
			});
		}
	};

	changeDateEnd = (date, dateString) => {
		if (date !== null) {
			this.setState({
				to_date: date.format("YYYY/MM/DD"),
			});
		}
	};

	fetchRowsSubject = () => {
		if (this.props.subjects instanceof Array) {
			return this.props.subjects.map((obj, i) => {
				return (
					<option value={obj._id} key={obj._id.toString()}>
						{obj.name}
					</option>
				);
			});
		}
	};


	fetchAccountant = () => {
		if (this.props.listAccountants instanceof Array) {			
			return this.props.listAccountants.map((obj, i) => {
				return (
					<option value={obj._id} key={obj._id.toString()}>
						{obj.fullname + '-' + obj.email}
					</option>
				);
			});
		}
	};

	renderTotal = () => {
		var total = 0;
		if (this.props.billReports.length > 0) {
			this.props.billReports.forEach((ele) => {
				if (ele.total) {
					total += ele.total;
				}
			});
		}
		return total;
	};

	fetchOptions = () => {
		if (this.props.classrooms instanceof Array) {
			if (this.state.subject_id !== "") {
				// eslint-disable-next-line array-callback-return
				return this.props.classrooms.map((obj, i) => {
					if (obj.subject.id === this.state.subject_id) {
						return (
							<option value={obj._id} key={obj._id.toString()}>
								{obj.name}
							</option>
						);
					}
				});
			}
		}
	};

	componentWillUnmount() {
		this.props.resetStateBill();
	}

	render() {
		let fileName = "doanhthu.csv";
		return (
			<div>
				<div className='page-hero page-container' id='page-hero'>
					<div className='padding d-flex'>
						<div className='page-title'>
							<h2 className='text-md text-highlight'>
								Báo cáo doanh thu
							</h2>
							<small className='text-muted'></small>
						</div>
						<div className='flex' />
						<div>
							<Link
								className='btn btn-sm btn-primary text-muted'
								to='/bill/create'
							>
								<span className='d-none d-sm-inline mx-1'>
									Đóng học phí
								</span>
							</Link>
						</div>
					</div>
				</div>
				<div className='page-content page-container' id='page-content'>
					<div className='padding'>
						<div className='mb-5'>
							<div className='toolbar'>
								<form className='flex' onSubmit={this.onSubmit}>
									<div className='form-group'>
										Lựa chọn một báo cáo:
										<input type="radio" name="by_type" checked={this.state.by_type === 'BY_SUBJECT'} value="BY_SUBJECT" onChange={this.onChange} style={{ marginLeft: 50 }} /> Báo cáo theo Môn
										<input type="radio" name="by_type" checked={this.state.by_type === 'BY_ACCOUNTANT'} value="BY_ACCOUNTANT" onChange={this.onChange} style={{ marginLeft: 50 }} /> Báo cáo theo thu ngân
									</div>
									<div className='input-group'>
										{this.state.show_subject ? <select
											style={{ maxWidth: 200 }}
											name='subject_id'
											id="subject_input"
											className='custom-select ml-2'
											onChange={this.onChange}
											value={this.state.subject_id}
											ref={(input) =>
												(this.subjectInput = input)
											}
										>
											<option value=''>
												Chọn môn
											</option>
											{this.fetchRowsSubject()}
										</select> : '' }
										{this.state.show_creator ? <select
											style={{ maxWidth: 300 }}
											id="creator_input"
											name='creator_id'
											className='custom-select ml-2'
											onChange={this.onChange}
											value={this.state.creator_id}
										>
											<option value=''>
												Chọn thu ngân
											</option>
											{this.fetchAccountant()}
										</select> : ''}
										<select
											style={{ maxWidth: 300 }}
											id="payment_method_input"
											name='payment_method'
											className='custom-select ml-2'
											onChange={this.onChange}
											value={this.state.payment_method}
										>
											<option value=''>Tất cả HTTT</option>
											<option value='CASH'>Tiền mặt</option>
											<option value='BANK_TRANSFER'>Chuyển khoản</option>
										</select>
										<DatePicker
											format={"DD/MM/YYYY"}
											onChange={this.changeDateStart}
											placeholder='Từ ngày'
											className='ml-2'
											ref={(input) =>
												(this.fromDateInput = input)
											}
										/>
										<DatePicker
											format={"DD/MM/YYYY"}
											onChange={this.changeDateEnd}
											placeholder='Đến ngày'
											className='ml-2'
											ref={(input) =>
												(this.toDateInput = input)
											}
										/>
										<button
											onClick={this.onSubmit}
											className='btn btn-sm btn-primary text-muted ml-2'
										>
											<span className='d-none d-sm-inline mx-1'>
												Lọc
											</span>
										</button>
										{this.props.billReports.length > 0 && (
											<div className='col-1'>
												<CSVLink
													filename={fileName}
													data={
														this.state.billReports
													}
													headers={this.state.headers}
													className='btn btn-primary'
												>
													Xuất excel
												</CSVLink>
											</div>
										)}
									</div>
								</form>
							</div>

							<div className='row'>
								<div className='col-sm-12'>
									{this.props.billReports.length > 0 && (
										<table className='table table-theme table-row v-middle'>
											<thead className='text-muted'>
												<tr>
													<th>STT</th>
													<th className='text-left'>
														{this.state.by_type === 'BY_ACCOUNTANT' ? 'Thu ngân' : 'Lớp học' }
													</th>
													<th className='text-right'>
														Doanh thu
													</th>
												</tr>
											</thead>
											<tbody>
												{this.fetchRows()}

												<tr>
													<td
														colSpan={3}
														className='text-right'
													>
														Tổng:{" "}
														{this.renderTotal().toLocaleString(
															"en-EN",
															{
																minimumFractionDigits: 0,
															}
														)}{" "}
														đ
													</td>
												</tr>
											</tbody>
										</table>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {	
	return {
		billReports: state.bill.billReports,
		classrooms: state.schedule.classrooms,
		subjects: state.subject.subjects,
		listAccountants: state.student.accountants
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			listAccountant,
			listBill,
			deleteBill,
			addDelete,
			checkAll,
			listSubject,
			listBillReport,
			resetStateBill,
		},
		dispatch
	);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BillReport)
);
