import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createAdmin } from "../../redux/student/action";
import { Editor } from "@tinymce/tinymce-react";
import { uploadImage } from "../../redux/category/action";
class AdminCreate extends Component {
	constructor(props) {
		super();
		this.state = {
			fullname: "",
			email: "",
			phone: "",
			user_group: "",
			password: "",
			description: "",
			total_classroom: 0,
			total_student: 0,
			content: "",
			is_show_profile: false,
			avatar_base64: "",
			link_fb: "",
			profile_pic_base64: "",
			featured_stats_box: {
				box1_num: "",
				box1_text: "",
				box2_num: "",
				box2_text: "",
				box3_num: "",
				box3_text: "",
				box4_num: "",
				box4_text: "",
			},
			featured_text_box: {
				box1_text: "",
				box2_text: "",
				box3_text: "",
			}
		};
	}

	_onChange = async (e) => {
		var name = e.target.name;
		var value = e.target.value;
		if (name === "avatar_base64" || name === "profile_pic_base64") {
			value = await new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(e.target.files[0]);
				reader.onload = () => {
					resolve(reader.result);
				};
				reader.onerror = (error) => reject(error);
			});
			value = value;
		}
		this.setState({
			[name]: value,
		});
	};

	_onChangeFeaturedBox = async (e) => {
		var name = e.target.name;
		var value = e.target.value;


		this.setState({
			featured_stats_box: {
				...this.state.featured_stats_box,
				[name]: value
			}
		})
	}

	_onChangeFeaturedText = async (e) => {
		var name = e.target.name;
		var value = e.target.value;


		this.setState({
			featured_text_box: {
				...this.state.featured_text_box,
				[name]: value
			}
		})
	}

	handleSubmit = async (e) => {
		e.preventDefault();
		let { box1_num, box2_num, box3_num, box4_num } = this.state.featured_stats_box;
		const data = {
			fullname: this.state.fullname,
			email: this.state.email,
			phone: this.state.phone,
			user_group: this.state.user_group,
			password: this.state.password,
			total_classroom: this.state.total_classroom,
			total_student: this.state.total_student,
			avatar_base64: this.state.avatar_base64,
			is_show_profile: this.state.is_show_profile,
			link_fb: this.state.link_fb,
			profile_pic_base64: this.state.profile_pic_base64,
			featured_stats_box: {
				...this.state.featured_stats_box,
				box1_num: parseInt(box1_num),
				box2_num: parseInt(box2_num),
				box3_num: parseInt(box3_num),
				box4_num: parseInt(box4_num),
			},
			featured_text_box: this.state.featured_text_box
		};
		data.description = this.state.description.toString();
		data.content = this.state.content.toString();
		await this.props.createAdmin(data);
		if (this.props.redirect === true) {
			await this.props.history.push("/admin");
		}
	};

	handleSave = async (e) => {
		e.preventDefault();
		let { box1_num, box2_num, box3_num, box4_num } = this.state.featured_stats_box;

		const data = {
			fullname: this.state.fullname,
			email: this.state.email,
			phone: this.state.phone,
			user_group: this.state.user_group,
			password: this.state.password,
			total_classroom: this.state.total_classroom,
			total_student: this.state.total_student,
			is_show_profile: this.state.is_show_profile,
			avatar_base64: this.state.avatar_base64,
			link_fb: this.state.link_fb,
			profile_pic_base64: this.state.profile_pic_base64,
			featured_stats_box: {
				...this.state.featured_stats_box,
				box1_num: parseInt(box1_num),
				box2_num: parseInt(box2_num),
				box3_num: parseInt(box3_num),
				box4_num: parseInt(box4_num),
			},
			featured_text_box: this.state.featured_text_box
		};
		data.description = this.state.description.toString();
		data.content = this.state.content.toString();
		await this.props.createAdmin(data);
		if (this.props.redirect === true) {
			await this.props.history.push("/admin");
		}
	};

	_handleEditorContentChange = (value, editor) => {
		this.setState({ content: value });
	};
	_handleEditorDescriptionChange = (value, editor) => {
		this.setState({ description: value });
	};

	_uploadImageCallBack = async (blobInfo, success, failure) => {
		let file = blobInfo.blob();
		const data = new FormData();
		data.append("files", file);

		await this.props.uploadImage(data);

		if (this.props.image != null) {
			success(this.props.image);
		} else {
			failure("Upload image fail");
		}
	};

	render() {
		return (
			<div>
				<div className="page-hero page-container" id="page-hero">
					<div className="padding d-flex">
						<div className="page-title">
							<h2 className="text-md text-highlight">Thêm mới admin</h2>
						</div>
						<div className="flex" />
						<div>
							<Link to={"/admin"} className="btn btn-sm text-white btn-primary">
								<span className="d-none d-sm-inline mx-1">Quay lại</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width={16}
									height={16}
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth={2}
									strokeLinecap="round"
									strokeLinejoin="round"
									className="feather feather-arrow-right"
								>
									<line x1={5} y1={12} x2={19} y2={12} />
									<polyline points="12 5 19 12 12 19" />
								</svg>
							</Link>
						</div>
					</div>
				</div>

				<div className="page-content page-container" id="page-content">
					<div className="padding">
						<div className="row">
							<div className="col-md-10">
								<div className="card">
									<div className="card-header">
										<strong>Thêm mới admin</strong>
									</div>
									<div className="card-body">
										<div className="form-group row">

											<div className="col-sm-6">
												<label className="col-form-label">
													Họ và tên
												</label>
												<input
													type="text"
													className="form-control"
													name="fullname"
													onChange={this._onChange}
													value={this.state.fullname}
												/>
											</div>
											<div className="col-sm-6">
												<label className="col-form-label">
													Số điện thoại
												</label>
												<input
													type="text"
													className="form-control"
													name="phone"
													onChange={this._onChange}
													value={this.state.phone}
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-sm-6">
												<label className="col-form-label">Email</label>
												<input
													type="email"
													className="form-control"
													name="email"
													onChange={this._onChange}
													value={this.state.email}
												/>
											</div>
											<div className="col-sm-6">
												<label className="col-form-label">Nhóm</label>
												<select
													className="custom-select"
													value={this.state.subject_id}
													name="user_group"
													onChange={this._onChange}
												>
													<option value="">-- Chọn nhóm --</option>
													<option value="ADMIN">ADMIN</option>
													<option value="MANAGER">Quản lý</option>
													<option value="TEACHER">Giáo viên</option>
													<option value="SUPPORTER">Trợ giảng</option>
													<option value="ACCOUNTANT">Thu ngân</option>
												</select>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-sm-6">
												<label className="col-form-label">
													Mật khẩu
												</label>
												<input
													type="text"
													className="form-control"
													name="password"
													onChange={this._onChange}
													value={this.state.password}
												/>
											</div>
											<div className="col-sm-6">
												<label className="col-form-label">
													Avatar
												</label>
												<input
													onChange={this._onChange}
													type="file"
													className="form-control-file"
													name="avatar_base64"
												/>
											</div>
										</div>
										<div className="form-group row">
											<div className="col-sm-6">
												<label className="col-form-label">
													Link facebook
												</label>
												<input
													type="text"
													className="form-control"
													name="link_fb"
													onChange={this._onChange}
													value={this.state.link_fb}
												/>
											</div>
											<div className="col-sm-6">
												<label className="col-form-label">
													Ảnh đại diện
												</label>
												<input
													onChange={this._onChange}
													type="file"
													className="form-control-file"
													name="profile_pic_base64"
												/>
											</div>
										</div>
										<div className="form-group row">


											<div className="col-sm-6">
												<label className="col-form-label">
													Tổng số lớp học
												</label>
												<input
													type="number"
													className="form-control"
													name="total_classroom"
													onChange={this._onChange}
													value={this.state.total_classroom}
												/>
											</div>
											<div className="col-sm-6">
												<label className="col-form-label">
													Tổng số học sinh
												</label>
												<input
													type="number"
													className="form-control"
													name="total_student"
													onChange={this._onChange}
													value={this.state.total_student}
												/>
											</div>
										</div>

										<div className="form-group row">
											<div className="col-sm-6">
												<label className="col-form-label">Box 1 - Số</label>
												<input
													type="number"
													className="form-control"
													name="box1_num"
													onChange={this._onChangeFeaturedBox}
													value={this.state.featured_stats_box.box1_num}
												/>
											</div>
											<div className="col-sm-6">
												<label className="col-form-label">Box 1 - Text</label>
												<textarea
													className="form-control custom-textarea"
													name="box1_text"
													onChange={this._onChangeFeaturedBox}
													value={this.state.featured_stats_box.box1_text}
													rows="1"
												>
												</textarea>
											</div>
										</div>

										<div className="form-group row">
											<div className="col-sm-6">
												<label className="col-form-label">Box 2 - Số</label>
												<input
													type="number"
													className="form-control"
													name="box2_num"
													onChange={this._onChangeFeaturedBox}
													value={this.state.featured_stats_box.box2_num}
												/>
											</div>
											<div className="col-sm-6">
												<label className="col-form-label">Box 2 - Text</label>
												<textarea
													className="form-control custom-textarea"
													name="box2_text"
													onChange={this._onChangeFeaturedBox}
													value={this.state.featured_stats_box.box2_text}
													rows="1"
												>
												</textarea>
											</div>
										</div>

										<div className="form-group row">
											<div className="col-sm-6">
												<label className="col-form-label">Box 3 - Số</label>
												<input
													type="number"
													className="form-control"
													name="box3_num"
													onChange={this._onChangeFeaturedBox}
													value={this.state.featured_stats_box.box3_num}
												/>
											</div>
											<div className="col-sm-6">
												<label className="col-form-label">Box3 - Text</label>
												<textarea
													className="form-control custom-textarea"
													name="box3_text"
													onChange={this._onChangeFeaturedBox}
													value={this.state.featured_stats_box.box3_text}
													rows="1"
												>
												</textarea>

											</div>
										</div>

										<div className="form-group row">
											<div className="col-sm-6">
												<label className="col-form-label">Box 4 - Số</label>
												<input
													type="number"
													className="form-control"
													name="box4_num"
													onChange={this._onChangeFeaturedBox}
													value={this.state.featured_stats_box.box4_num}
												/>
											</div>
											<div className="col-sm-6">
												<label className="col-form-label">Box 4 - Text</label>
												<textarea
													className="form-control custom-textarea"
													name="box4_text"
													onChange={this._onChangeFeaturedBox}
													value={this.state.featured_stats_box.box4_text}
													rows="1"
												>
												</textarea>

											</div>
										</div>

										<div className="form-group row">
											<div className="col-sm-6">
												<label className="col-form-label">Featured text 1</label>
												<textarea
													className="form-control custom-textarea"
													name="box1_text"
													onChange={this._onChangeFeaturedText}
													value={this.state.featured_text_box.box1_text}
													rows="1"
												>
												</textarea>
											</div>
											<div className="col-sm-6">
												<label className="col-form-label">Featured text 2</label>
												<textarea
													className="form-control custom-textarea"
													name="box2_text"
													onChange={this._onChangeFeaturedText}
													value={this.state.featured_text_box.box2_text}
													rows="1"
												>
												</textarea>

											</div>
										</div>
										<div className="form-group row">
											<div className="col-sm-6">
												<label className="col-form-label">Featured text 3</label>
												<textarea
													className="form-control custom-textarea"
													name="box3_text"
													onChange={this._onChangeFeaturedText}
													value={this.state.featured_text_box.box3_text}
													rows="1"
												>
												</textarea>
											</div>
										</div>


										<div className="form-group row">
											<label className="col-sm-4 col-form-label">
												Mô tả ngắn
											</label>
											<div className="col-sm-8">
												<Editor
													onInit={(evt, editor) => {
														this._handleEditorDescriptionChange(
															this.state.description,
															editor
														);
													}}
													value={this.state.description}
													init={{
														height: 500,
														menubar: false,
														images_file_types:
															"jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
														plugins: [
															"advlist autolink lists link image charmap print preview anchor",
															"searchreplace visualblocks code fullscreen",
															"insertdatetime media table paste code help wordcount tiny_mce_wiris",
														],
														external_plugins: {
															tiny_mce_wiris:
																"https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
														},
														toolbar:
															"undo redo | formatselect | " +
															"bold italic backcolor | image | alignleft aligncenter " +
															"alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
															"searchreplace visualblocks code fullscreen | " +
															"lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
														fontsize_formats:
															"8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
														content_style:
															"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
														draggable_modal: true,
														htmlAllowedTags: [".*"],
														htmlAllowedAttrs: [".*"],
														images_upload_handler: (
															blobInfo,
															success,
															failure
														) =>
															this._uploadImageCallBack(
																blobInfo,
																success,
																failure
															),
													}}
													onEditorChange={this._handleEditorDescriptionChange}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-sm-4 col-form-label">
												Nội dung chi tiết
											</label>
											<div className="col-sm-8">
												<Editor
													onInit={(evt, editor) => {
														this._handleEditorContentChange(
															this.state.content,
															editor
														);
													}}
													value={this.state.content}
													init={{
														height: 500,
														menubar: false,
														images_file_types:
															"jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
														plugins: [
															"advlist autolink lists link image charmap print preview anchor",
															"searchreplace visualblocks code fullscreen",
															"insertdatetime media table paste code help wordcount tiny_mce_wiris",
														],
														external_plugins: {
															tiny_mce_wiris:
																"https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
														},
														toolbar:
															"undo redo | formatselect | " +
															"bold italic backcolor | image | alignleft aligncenter " +
															"alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
															"searchreplace visualblocks code fullscreen | " +
															"lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
														fontsize_formats:
															"8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
														content_style:
															"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
														draggable_modal: true,
														htmlAllowedTags: [".*"],
														htmlAllowedAttrs: [".*"],
														images_upload_handler: (
															blobInfo,
															success,
															failure
														) =>
															this._uploadImageCallBack(
																blobInfo,
																success,
																failure
															),
													}}
													onEditorChange={this._handleEditorContentChange}
												/>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-sm-4 col-form-label">
												Hiển thị Profile ?
											</label>
											<div className="col-sm-8">
												<div className="form-check float-left">
													<input
														className="form-check-input"
														type="radio"
														name="is_show_profile"
														value="true"
														id="gridRadios1"
														onChange={this._onChange}
														// defaultValue="option1"
													/>
													<label
														className="form-check-label"
														htmlFor="gridRadios1"
													>
														Hiển thị
													</label>
												</div>
												<div className="form-check float-left ml-4">
													<input
														className="form-check-input"
														type="radio"
														name="is_show_profile"
														value="false"
														id="gridRadios2"
														onChange={this._onChange}
														// defaultValue="option2"
													/>
													<label
														className="form-check-label"
														htmlFor="gridRadios2"
													>
														Ẩn
													</label>
												</div>
											</div>
										</div>

										<div className="form-group row">
											<div className="col-sm-12 text-right">
												<button
													className="btn btn-primary mt-2"
													onClick={this.handleSubmit}
												>
													Lưu
												</button>
												<button
													className="btn btn-primary mt-2 ml-2"
													onClick={this.handleSave}
												>
													Lưu &amp; Thêm mới
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		redirect: state.student.redirect,
		image: state.question.image,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ createAdmin, uploadImage }, dispatch);
}

let ClassCreateContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AdminCreate)
);

export default ClassCreateContainer;
