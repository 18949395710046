import React, { Component } from 'react';
import Moment from 'moment';
import { notification } from 'antd';
import Pagination from 'react-js-pagination';
import {
	listReview,
	deleteReview,
	addDelete,
	checkAll,
	updateReview
} from '../../redux/review/action';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Row extends Component {
	constructor(props) {
		super();
		this.state = {
			check: false,
			status: true,
			rating: 0,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.check !== nextProps.check) {
			this.setState({
				check: nextProps.check,
			});
		}
	}

	handleCheck = e => {
		if (e.target.checked) {
			this.props.addDelete(this.props.obj._id, 'add');
			this.setState({
				check: e.target.checked,
			});
		} else {
			this.props.addDelete(this.props.obj._id, 'remove');
			this.setState({
				check: e.target.checked,
			});
		}
	};

	componentDidMount() {
		this.setState({
			check: false,
			status: this.props.obj.status,
			rating: this.props.obj.rating
		});
	}

	handleChangeStatus = async e => {
		e.preventDefault();
		var name = e.target.name;
		let checke = e.target.checked;
		var value = e.target.value;
		if (name === 'rating')
			checke = parseInt(value);
		await this.setState({
			[name]: checke,
		});

		const data = {
			id: this.props.obj._id,
			name: this.props.obj.name,
			classroom_id: this.props.obj.classroom.id,
			comment: this.props.obj.comment,
			status: this.state.status,
			rating: this.state.rating
		};
		await this.props.updateReview(data);
	};

	render() {
		return (
			<tr className="v-middle" data-id={17}>
				<td>
					<label className="ui-check m-0">
						<input
							type="checkbox"
							name="id"
							onChange={this.handleCheck}
							checked={this.state.check === true ? 'checked' : ''}
						/>{' '}
						<i />
					</label>
				</td>
				<td className="flex">
					<Link
						className="item-author text-color"
						to={'/review/' + this.props.obj._id + '/edit'}>
						{this.props.obj.name}
					</Link>
				</td>
				<td className="text-left">
					<span className="item-amount d-none d-sm-block text-sm">
						{this.props.obj.classroom.name}
					</span>
				</td>
				<td className="text-left">
					<span className="item-amount d-none d-sm-block text-sm">
						{this.props.obj.comment}
					</span>
				</td>
				<td className="text-left">
					<select className="form-control" name="rating" value={this.props.obj.rating} onChange={this.handleChangeStatus}>
						<option value={1}>1</option>
						<option value={2}>2</option>
						<option value={3}>3</option>
						<option value={4}>4</option>
						<option value={5}>5</option>
					</select>
				</td>
				<td className="text-left">
					<span className="item-amount d-none d-sm-block text-sm">
						<label className="ui-switch ui-switch-md info m-t-xs">
							<input
								type="checkbox"
								name="status"
								value={this.props.obj._id}
								checked={this.state.status === true ? 'checked' : ''}
								onChange={this.handleChangeStatus}
							/>{' '}
							<i />
						</label>
					</span>
				</td>
				<td className="text-center">
					<span className="item-amount d-none d-sm-block text-sm">
						{this.props.obj.updated_at &&
							Moment(this.props.obj.updated_at).format(
								'DD/MM/YYYY HH:mm:ss',
							)}
					</span>
				</td>
				<td>
					<div className="item-action dropdown">
						<a
							href="/"
							data-toggle="dropdown"
							className="text-muted">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={16}
								height={16}
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
								className="feather feather-more-vertical">
								<circle cx={12} cy={12} r={1} />
								<circle cx={12} cy={5} r={1} />
								<circle cx={12} cy={19} r={1} />
							</svg>
						</a>
						<div
							className="dropdown-menu dropdown-menu-right bg-white"
							role="menu">
							<Link
								className="dropdown-item"
								to={'/review/' + this.props.obj._id + '/edit'}>
								Sửa
							</Link>
							<div className="dropdown-divider" />
							<button
								onClick={e =>
									this.props.addDelete(this.props.obj._id)
								}
								className="dropdown-item trash"
								data-toggle="modal"
								data-target="#delete-video"
								data-toggle-class="fade-down"
								data-toggle-class-target=".animate">
								Xóa
							</button>
						</div>
					</div>
				</td>
			</tr>
		);
	}
}

class Review extends Component {
	constructor(props) {
		super();
		this.state = {
			data: [],
			limit: 20,
			checkAll: false,
		};
	}

	fetchRows() {
		if (this.props.reviews instanceof Array) {
			return this.props.reviews.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						addDelete={this.props.addDelete}
						getData={this.getData}
						check={this.props.check}
						updateReview={this.props.updateReview}
					/>
				);
			});
		}
	}

	onChange = e => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};

	getData = (pageNumber = 1) => {
		const data = {
			page: pageNumber,
			limit: this.props.limit,
		};
		if (this.state.keyword != null) {
			data['keyword'] = this.state.keyword;
		}
		return data;
	};

	async componentDidMount() {
		await this.props.listReview(this.getData());
		if (this.props.limit) {
			await this.setState({
				limit: this.props.limit,
				checkAll: false,
			});
		}
	}

	onSubmit = e => {
		e.preventDefault();
		this.props.listReview(this.getData());
	};

	handleChangePage = async pageNumber => {
		await this.props.listReview(this.getData(pageNumber));
	};

	handleDelete = async () => {
		const data = {
			ids: this.props.ids,
		};
		if (data.ids.length !== 0) {
			await this.props.deleteReview(data);
			await this.props.listReview(this.getData());
		} else {
			notification.warning({
				message: 'Chưa chọn mục nào !',
				placement: 'topRight',
				top: 50,
				duration: 3,
			});
		}
	};

	handleChange = async e => {
		var name = e.target.name;
		var value = e.target.value;
		await this.setState({
			[name]: value,
		});
		await this.props.listReview(this.getData());
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.checkAll !== nextProps.check) {
			this.setState({
				checkAll: nextProps.check,
			});
		}
	}

	handleCheckAll = e => {
		if (e.target.checked) {
			this.props.checkAll(true);
			this.setState({
				checkAll: e.target.checked,
			});
		} else {
			this.props.checkAll(false);
			this.setState({
				checkAll: e.target.checked,
			});
		}
	};

	render() {
		let displayFrom =
			this.props.page === 1
				? 1
				: (parseInt(this.props.page) - 1) * this.props.limit;
		let displayTo =
			this.props.page === 1
				? this.props.limit
				: displayFrom + this.props.limit;
		displayTo = displayTo > this.props.total ? this.props.total : displayTo;
		return (
			<div>
				<div className="page-hero page-container" id="page-hero">
					<div className="padding d-flex">
						<div className="page-title">
							<h2 className="text-md text-highlight">Đánh giá lớp</h2>
						</div>
						<div className="flex" />
						<div>
							<Link
								to={`/review/create`}
								className="btn btn-sm btn-primary text-muted">
								<span className="d-none d-sm-inline mx-1">
									Thêm mới
								</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width={16}
									height={16}
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth={2}
									strokeLinecap="round"
									strokeLinejoin="round"
									className="feather feather-arrow-right">
									<line x1={5} y1={12} x2={19} y2={12} />
									<polyline points="12 5 19 12 12 19" />
								</svg>
							</Link>
						</div>
					</div>
				</div>
				<div className="page-content page-container" id="page-content">
					<div className="padding">
						<div className="mb-5">
							<div className="toolbar">
								<div className="btn-group">
									{this.props.ids.length !== 0 ? (
										<button
											className="btn btn-icon"
											data-toggle="modal"
											data-target="#delete-video"
											data-toggle-class="fade-down"
											data-toggle-class-target=".animate"
											title="Trash"
											id="btn-trash">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width={16}
												height={16}
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												strokeWidth={2}
												strokeLinecap="round"
												strokeLinejoin="round"
												className="feather feather-trash text-muted">
												<polyline points="3 6 5 6 21 6" />
												<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
											</svg>
										</button>
									) : (
											<button
												className="btn btn-icon"
												onClick={this.handleDelete}
												title="Trash"
												id="btn-trash">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={16}
													height={16}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-trash text-muted">
													<polyline points="3 6 5 6 21 6" />
													<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
												</svg>
											</button>
										)}
								</div>
								<form className="flex" onSubmit={this.onSubmit}>
									<div className="input-group">
										<input
											type="text"
											className="form-control form-control-theme keyword-custom"
											placeholder="Nhập từ khoá tìm kiếm..."
											onChange={this.onChange}
											name="keyword"
										/>{' '}
										<span className="input-group-append">
											<button
												className="btn btn-white btn-sm"
												type="submit">
												<span className="d-flex text-muted">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width={16}
														height={16}
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														strokeWidth={2}
														strokeLinecap="round"
														strokeLinejoin="round"
														className="feather feather-search">
														<circle
															cx={11}
															cy={11}
															r={8}
														/>
														<line
															x1={21}
															y1={21}
															x2="16.65"
															y2="16.65"
														/>
													</svg>
												</span>
											</button>
										</span>
									</div>
								</form>
							</div>

							<div className="row">
								<div className="col-sm-12">
									<table className="table table-theme table-row v-middle">
										<thead className="text-muted">
											<tr>
												<th width="10px">
													<label className="ui-check m-0">
														<input
															type="checkbox"
															name="id"
															onChange={
																this
																	.handleCheckAll
															}
															checked={
																this.state
																	.checkAll ===
																	true
																	? 'checked'
																	: ''
															}
														/>{' '}
														<i />
													</label>
												</th>
												<th>Tên</th>
												<th className="text-left">
													Lớp
												</th>
												<th width="125px" className="text-left">
													Đánh giá
												</th>
												<th className="text-left">
													Số sao
												</th>
												<th className="text-left">
													Kích hoạt
												</th>
												<th className="text-center">
													Thời gian cập nhật
												</th>
												<th width="50px" />
											</tr>
										</thead>
										<tbody>{this.fetchRows()}</tbody>
									</table>
								</div>
							</div>

							<div className="row listing-footer">
								<div className="col-sm-2">
									<select
										className="custom-select w-96"
										name="limit"
										value={this.state.limit}
										onChange={this.handleChange}>
										<option value="20">20</option>
										<option value="50">50</option>
										<option value="100">100</option>
										<option value="-1">ALL</option>
									</select>
								</div>
								<div className="col-sm-5 showing-text">
									{' '}
									Hiển thị từ <b>{displayFrom}</b> đến{' '}
									<b>{displayTo}</b> trong tổng số{' '}
									<b>{this.props.total}</b>
								</div>
								{this.props.total !== 0 ? (
									<div className="col-sm-5 text-right">
										<Pagination
											activePage={this.props.page}
											itemsCountPerPage={this.props.limit}
											totalItemsCount={this.props.total}
											pageRangeDisplayed={10}
											onChange={this.handleChangePage}
										/>
									</div>
								) : (
										<div className="">Không có bản ghi nào</div>
									)}
							</div>

							<div
								id="delete-video"
								className="modal fade"
								data-backdrop="true"
								style={{ display: 'none' }}
								aria-hidden="true">
								<div
									className="modal-dialog animate fade-down"
									data-class="fade-down">
									<div className="modal-content">
										<div className="modal-header">
											<div className="modal-title text-md">
												Thông báo
											</div>
											<button
												className="close"
												data-dismiss="modal">
												×
											</button>
										</div>
										<div className="modal-body">
											<div className="p-4 text-center">
												<p>
													Bạn chắc chắn muốn xóa bản
													ghi này chứ?
												</p>
											</div>
										</div>
										<div className="modal-footer">
											<button
												type="button"
												className="btn btn-light"
												data-dismiss="modal">
												Đóng
											</button>
											<button
												type="button"
												onClick={this.handleDelete}
												className="btn btn-danger"
												data-dismiss="modal">
												Xoá
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		subjects: state.subject.subjects,
		reviews: state.review.reviews ? state.review.reviews : [],
		limit: state.chapter.limit,
		page: state.chapter.page,
		total: state.chapter.total,
		ids: state.chapter.ids,
		check: state.chapter.checkAll,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{ listReview, deleteReview, addDelete, checkAll, updateReview },
		dispatch,
	);
}

let Container = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Review),
);
export default Container;
