import React, { Component } from 'react';
import Moment from 'moment';
import { notification } from 'antd';
import Pagination from 'react-js-pagination';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { listBlog } from '../../redux/blog/action';
import { param } from 'jquery';

class Row extends Component {
    constructor(props) {
        super();
        this.state = {
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }


    componentDidMount() {
        const params = {
            limit: 5,
            page: 2
        };
        const blogs  = this.props.listBlog(param);
    }

    render() {
        return (<tr className="v-middle" data-id={17}>
            <td><label className="ui-check m-0"><input type="checkbox" name="id" /> <i /></label></td>
            <td className="flex"><a className="item-author text-color" href="/classroom-group/61232427e86d60694f94105c/edit">Tiếng Anh Cô Trang</a></td>
            <td className="text-left"><span className="item-amount d-none d-sm-block text-sm">Tiếng Anh Cô Trang</span></td>
            <td className="text-left"><input type="number" className="form-control" name="ordering" defaultValue={0} /></td>
            <td className="text-left"><span className="item-amount d-none d-sm-block text-sm"><label className="ui-switch ui-switch-md info m-t-xs"><input type="checkbox" name="is_show_home" defaultValue="61232427e86d60694f94105c" /> <i /></label></span></td>
            <td className="text-left"><span className="item-amount d-none d-sm-block text-sm"><label className="ui-switch ui-switch-md info m-t-xs"><input type="checkbox" name="status" defaultValue="61232427e86d60694f94105c" defaultChecked /> <i /></label></span></td>
            <td className="text-center"><span className="item-amount d-none d-sm-block text-sm">06/09/2021 14:59:00</span></td>
            <td>
                <div className="item-action dropdown">
                    <a href="/" data-toggle="dropdown" className="text-muted">
                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-vertical">
                            <circle cx={12} cy={12} r={1} />
                            <circle cx={12} cy={5} r={1} />
                            <circle cx={12} cy={19} r={1} />
                        </svg>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right bg-white" role="menu">
                        <a className="dropdown-item" href="/classroom-group/61232427e86d60694f94105c/edit">Sửa</a>
                        <div className="dropdown-divider" /><button className="dropdown-item trash" data-toggle="modal" data-target="#delete-video" data-toggle-class="fade-down" data-toggle-class-target=".animate">Xóa</button></div>
                </div>
            </td>
        </tr>);
    }
}

class Blog extends Component {
    constructor(props) {
        super();
        this.state = {
            data: [],
            limit: 20,
            checkAll: false,
        };
    }

    fetchRows() {
    }

    onChange = e => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState({
            [name]: value,
        });
    };

    async componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    render() {
        return (
            <div>
                <div className="page-hero page-container" id="page-hero">
                    <div className="padding d-flex">
                        <div className="page-title">
                            <h2 className="text-md text-highlight">Blog</h2>
                        </div>
                        <div className="flex" />
                        <div>
                            <Link
                                to={`/classroom-group/create`}
                                className="btn btn-sm btn-primary text-muted">
                                <span className="d-none d-sm-inline mx-1">
                                    Thêm mới
                                </span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-right">
                                    <line x1={5} y1={12} x2={19} y2={12} />
                                    <polyline points="12 5 19 12 12 19" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="page-content page-container" id="page-content">
                    <div className="padding">
                        <div className="mb-5">
                            <div className="toolbar">
                                <div className="btn-group">
                                    <button
                                        className="btn btn-icon"
                                        title="Trash"
                                        id="btn-trash">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-trash text-muted">
                                            <polyline points="3 6 5 6 21 6" />
                                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                        </svg>
                                    </button>

                                </div>
                                <form className="flex">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control form-control-theme keyword-custom"
                                            placeholder="Nhập từ khoá tìm kiếm..."
                                            onChange={this.onChange}
                                            name="keyword"
                                        />{' '}
                                        <span className="input-group-append">
                                            <button
                                                className="btn btn-white btn-sm"
                                                type="submit">
                                                <span className="d-flex text-muted">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={16}
                                                        height={16}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="feather feather-search">
                                                        <circle
                                                            cx={11}
                                                            cy={11}
                                                            r={8}
                                                        />
                                                        <line
                                                            x1={21}
                                                            y1={21}
                                                            x2="16.65"
                                                            y2="16.65"
                                                        />
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                    </div>
                                </form>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table table-theme table-row v-middle">
                                        <thead className="text-muted">
                                            <tr>
                                                <th width="10px">
                                                    <label className="ui-check m-0">
                                                        <input
                                                            type="checkbox"
                                                            name="id"
                                                        />{' '}
                                                        <i />
                                                    </label>
                                                </th>
                                                <th>Tên</th>
                                                <th className="text-left">
                                                    Môn học
                                                </th>
                                                <th width="125px" className="text-left">
                                                    Thứ tự
                                                </th>
                                                <th className="text-left">
                                                    Trang chủ
                                                </th>
                                                <th className="text-left">
                                                    Kích hoạt
                                                </th>
                                                <th className="text-center">
                                                    Thời gian cập nhật
                                                </th>
                                                <th width="50px" />
                                            </tr>
                                        </thead>
                                        <tbody>{this.fetchRows()}</tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="row listing-footer">
                                <div className="col-sm-2">
                                    <select
                                        className="custom-select w-96"
                                        name="limit">
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="-1">ALL</option>
                                    </select>
                                </div>
                                <div className="col-sm-5 showing-text">

                                </div>

                            </div>

                            <div
                                id="delete-video"
                                className="modal fade"
                                data-backdrop="true"
                                style={{ display: 'none' }}
                                aria-hidden="true">
                                <div
                                    className="modal-dialog animate fade-down"
                                    data-class="fade-down">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <div className="modal-title text-md">
                                                Thông báo
                                            </div>
                                            <button
                                                className="close"
                                                data-dismiss="modal">
                                                ×
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="p-4 text-center">
                                                <p>
                                                    Bạn chắc chắn muốn xóa bản
                                                    ghi này chứ?
                                                </p>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-light"
                                                data-dismiss="modal">
                                                Đóng
                                            </button>
                                            <button
                                                type="button"
                                                onClick={this.handleDelete}
                                                className="btn btn-danger"
                                                data-dismiss="modal">
                                                Xoá
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { listBlog },
        dispatch,
    );
}

let BlogContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Blog),
);
export default BlogContainer;
