import React, { useEffect } from "react";
import {
	Link,
	withRouter,
	Switch,
	NavLink,
} from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { logout, loadUser } from "../redux/auth/action";

import Changepassword from "./Changepassword";
import Profile from "./Profile";
import Student from "./student/Student";
import StudentCreate from "./student/StudentCreate";
import StudentEdit from "./student/StudentEdit";
import Admin from "./admin/Admin";
import AdminCreate from "./admin/AdminCreate";
import AdminEdit from "./admin/AdminEdit";
import Exam from "./exam/Exam";
import ExamCreate from "./exam/ExamCreate";
import Testing from "./testing/Testing";

import Question from "./question/Question";
import QuestionCreate from "./question/QuestionCreate";
import QuestionEdit from "./question/QuestionEdit";

import ExamEdit from "./exam/ExamEdit";
import ExamReport from "./exam/report/ExamReport.js";

import TestingEdit from "./testing/TestingEdit";
import Subject from "./subject/Subject";
import subjectCreate from "./subject/SubjectCreate";
import SubjectEdit from "./subject/SubjectEdit";
import Chapter from "./chapter/chapter";
import ChapterCreate from "./chapter/ChapterCreate";
import chapterEdit from "./chapter/ChapterEdit";
import Category from "./category/Category";
import CategoryCreate from "./category/CategoryCreate";
import CategoryEdit from "./category/CategoryEdit";
import { isUndefined } from "util";

import ClassroomGroup from "./classroom-group/ClassroomGroup";
import ClassroomGroupCreate from "./classroom-group/ClassroomGroupCreate";
import ClassroomGroupEdit from "./classroom-group/ClassroomGroupEdit";

import Classroom from "./classroom/Classroom";
import ClassroomCreate from "./classroom/ClassroomCreate";
import ClassroomEdit from "./classroom/ClassroomEdit";
import ClassroomCode from "./classroom/ClassroomCode";
import ClassroomReport from "./classroom/ClassroomReport";
import ClassroomMember from "./classroom/ClassroomMember";

import Document from "./document/Document";
import DocumentCreate from "./document/DocumentCreate";
import DocumentEdit from "./document/DocumentEdit";

import ListRegister from "./register/ListRegister";
import RegisterEdit from "./register/RegisterEdit";

import Setting from "./setting/Setting.js";
import Message from "./message/Message.js";
import MessageCreate from "./message/MessageCreate.js";
import MessageEdit from "./message/MessageEdit.js";
import Schedule from "./schedule/Schedule";
import Home from "./home/Home";

import Bill from "./bill/Bill";
import BillCreate from "./bill/BillCreate";
import BillEdit from "./bill/BillEdit";
import BillReport from "./bill/BillReport";
import PrivateRoute from "../routing/PrivateRoute";
import { isNull } from "lodash";
import CheckCard from "./check-card/CheckCard";
import Diligence from "./diligence/Diligence";

import Review from "./review/Review";
import ReviewCreate from "./review/ReviewCreate";
import ReviewEdit from "./review/ReviewEdit";

import Book from "./book/Book";
import BookCreate from "./book/BookCreate";
import BookEdit from "./book/BookEdit";

import BookReview from "./book-review/BookReview";
import BookReviewEdit from "./book-review/BookReviewEdit";
import BookReviewCreate from "./book-review/BookReviewCreate";

import Blog from "./blog/Blog";

import AdultEvaluation from './adult-evaluation/adultEvaluation';
import AdultEvaluationCreate from './adult-evaluation/adultEvaluationCreate';
import AdultEvaluationEdit from './adult-evaluation/adultEvaluationEdit';

import Magazine from './magazine/magazine';
import MagazineCreate from './magazine/magazineCreate';
import MagazineEdit from './magazine/magazineEdit';

import Credit from './credit/Credit';


import Order from './order/Order';
import OrderDetails from './order/OrderDetails';

import Coupon from './coupon/Coupon.js';

import BookCategory from './book-category/BookCategory';
import BookCategoryCreate from './book-category/BookCategoryCreate';
import BookCategoryEdit from './book-category/BookCategoryEdit';

import ExamCategory from './exam-category/ExamCategory';
import ExamCategoryCreate from './exam-category/ExamCategoryCreate';
import ExamCategoryEdit from './exam-category/ExamCategoryEdit';

import ReportBug from './bug/Bug';

const Master = ({ loadUser, logout, user, history }) => {
	let groupUser = 1;

	useEffect(() => {
		loadUser();
	}, []);

	const handleLogout = async (e) => {
		logout();
		history.push("/login");
	};

	return (
		<div className='layout-row'>
			<div
				id='aside'
				className='page-sidenav no-shrink bg-light nav-dropdown fade'
				aria-hidden='true'
			>
				<div className='sidenav h-100 modal-dialog bg-light'>
					<div
						className='navbar'
						style={{
							background: "#f5f5f6",
							boxShadow: "0 1px 3px rgba(0, 0, 0, .05)",
						}}
					>
						<a href='/' className='navbar-brand'>
							<img
								alt='LTTD'
								src='https://cdn.luyenthitiendat.vn/assets/DAICOVIET-LOGO.png'
							/>
							<span className='hidden-folded d-inline l-s-n-1x'>
								Luyện Thi Đại Cồ Việt
							</span>
						</a>
					</div>
					<div className='flex scrollable hover'>
						<div className='nav-active-text-primary' data-nav>
							{groupUser === 1 ? (
								<ul className='nav bg'>
									<li style={{ display: "none" }}>
										<NavLink
											activeStyle={{ color: "#448bff" }}
											to={`/dashboard`}
										>
											<span className='nav-icon text-primary'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-home'
												>
													<path d='M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z' />
													<polyline points='9 22 9 12 15 12 15 22' />
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Tổng quan
											</span>
										</NavLink>
									</li>

									<li className='nav-header hidden-folded'>
										<span className='text-muted'>
											Danh mục
										</span>
									</li>

									<li>
										<NavLink
											activeStyle={{ color: "#448bff" }}
											to={`/home`}
										>
											<span className='nav-icon text-primary'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-users'
												>
													<path d='M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2' />
													<circle
														cx={9}
														cy={7}
														r={4}
													/>
													<path d='M23 21v-2a4 4 0 0 0-3-3.87' />
													<path d='M16 3.13a4 4 0 0 1 0 7.75' />
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Trang chủ
											</span>
										</NavLink>
									</li>

									<li>
										<NavLink
											activeStyle={{ color: "#448bff" }}
											to={`/registration`}
										>
											<span className='nav-icon text-primary'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-users'
												>
													<path d='M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2' />
													<circle
														cx={9}
														cy={7}
														r={4}
													/>
													<path d='M23 21v-2a4 4 0 0 0-3-3.87' />
													<path d='M16 3.13a4 4 0 0 1 0 7.75' />
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Đăng ký
											</span>
										</NavLink>
									</li>

									<li>
										<NavLink
											activeStyle={{ color: "#448bff" }}
											to={`/student`}
										>
											<span className='nav-icon text-primary'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-users'
												>
													<path d='M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2' />
													<circle
														cx={9}
														cy={7}
														r={4}
													/>
													<path d='M23 21v-2a4 4 0 0 0-3-3.87' />
													<path d='M16 3.13a4 4 0 0 1 0 7.75' />
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Học sinh
											</span>
										</NavLink>
									</li>
									<li>
										<a href='#'>
											<span className='nav-icon'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-grid'
												>
													<rect
														x={3}
														y={3}
														width={7}
														height={7}
													/>
													<rect
														x={14}
														y={3}
														width={7}
														height={7}
													/>
													<rect
														x={14}
														y={14}
														width={7}
														height={7}
													/>
													<rect
														x={3}
														y={14}
														width={7}
														height={7}
													/>
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Đào tạo
											</span>{" "}
											<span className='nav-caret' />
										</a>
										<ul className='nav-sub nav-mega'>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/classroom-group`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Danh mục Lớp
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/classroom`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Lớp học
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/subject`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Môn học
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/chapter`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Chương
													</span>
												</NavLink>
											</li>

											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/category`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Bài giảng
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/testing`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Bài kiểm tra
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/schedule`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Thời khóa biểu
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/review`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Đánh giá lớp học
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/book`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Sách
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/book-category`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Danh mục Sách
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/book/review`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Đánh giá Sách
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/adult-evaluation`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Đánh giá của phụ huynh
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/magazine`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Bài viết báo chí
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/report-bug`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Báo lỗi
													</span>
												</NavLink>
											</li>
										</ul>
									</li>
									<li>
										<a href='#'>
											<span className='nav-icon'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-grid'
												>
													<rect
														x={3}
														y={3}
														width={7}
														height={7}
													/>
													<rect
														x={14}
														y={3}
														width={7}
														height={7}
													/>
													<rect
														x={14}
														y={14}
														width={7}
														height={7}
													/>
													<rect
														x={3}
														y={14}
														width={7}
														height={7}
													/>
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Học phí
											</span>{" "}
											<span className='nav-caret' />
										</a>
										<ul className='nav-sub nav-mega'>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/bill/create`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Đóng học phí
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/bill`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Phiếu thu
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/bill-report`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Báo cáo danh thu
													</span>
												</NavLink>
											</li>
										</ul>
									</li>
									<li>
										<a href='#'>
											<span className='nav-icon'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='16'
													height='16'
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-package mx-2'
												>
													<path d='M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z'></path>
													<polyline points='2.32 6.16 12 11 21.68 6.16'></polyline>
													<line
														x1='12'
														y1='22.76'
														x2='12'
														y2='11'
													></line>
													<line
														x1='7'
														y1='3.5'
														x2='17'
														y2='8.5'
													></line>
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Tài nguyên
											</span>{" "}
											<span className='nav-caret' />
										</a>
										<ul className='nav-sub nav-mega'>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/question`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Kho câu hỏi
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/exam-category`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Danh mục đề thi
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/exam`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Kho đề
													</span>
												</NavLink>
											</li>
											<li>
												<NavLink
													activeStyle={{
														color: "#448bff",
													}}
													to={`/document`}
												>
													<span className='nav-icon text-primary'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='16'
															height='16'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
															className='feather feather-tag mx-2'
														>
															<path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z'></path>
															<line
																x1='7'
																y1='7'
																x2='7'
																y2='7'
															></line>
														</svg>
													</span>{" "}
													<span className='nav-text'>
														Kho tài liệu
													</span>
												</NavLink>
											</li>
										</ul>
									</li>

									<li>
										<NavLink
											activeStyle={{ color: "#448bff" }}
											to={`/check-card`}
										>
											<span className='nav-icon text-primary'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-users'
												>
													<path d='M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2' />
													<circle
														cx={9}
														cy={7}
														r={4}
													/>
													<path d='M23 21v-2a4 4 0 0 0-3-3.87' />
													<path d='M16 3.13a4 4 0 0 1 0 7.75' />
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Kiểm tra thẻ
											</span>
										</NavLink>
									</li>
									<li>
										<NavLink
											activeStyle={{ color: "#448bff" }}
											to={`/diligence`}
										>
											<span className='nav-icon text-primary'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-users'
												>
													<path d='M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2' />
													<circle
														cx={9}
														cy={7}
														r={4}
													/>
													<path d='M23 21v-2a4 4 0 0 0-3-3.87' />
													<path d='M16 3.13a4 4 0 0 1 0 7.75' />
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Kiểm tra chuyên cần
											</span>
										</NavLink>
									</li>



									<li>
										<NavLink
											activeStyle={{ color: "#448bff" }}
											to={`/message`}
										>
											<span className='nav-icon text-primary'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-bell mx-2'
												>
													<path d='M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0' />
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Thông báo
											</span>
										</NavLink>
									</li>
									<li>
										<NavLink
											activeStyle={{ color: "#448bff" }}
											to={`/credit`}
										>
											<span className='nav-icon text-primary'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-bell mx-2'
												>
													<path d='M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0' />
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Lịch sử nạp tiền
											</span>
										</NavLink>
									</li>
									<li>
										<NavLink
											activeStyle={{ color: "#448bff" }}
											to={`/order`}
										>
											<span className='nav-icon text-primary'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-bell mx-2'
												>
													<path d='M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0' />
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Đơn hàng
											</span>
										</NavLink>
									</li>
									<li>
										<NavLink
											activeStyle={{ color: "#448bff" }}
											to={`/coupon`}
										>
											<span className='nav-icon text-primary'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-bell mx-2'
												>
													<path d='M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0' />
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Khuyến mãi
											</span>
										</NavLink>
									</li>
									<li>
										<NavLink
											activeStyle={{ color: "#448bff" }}
											to={`/admin`}
										>
											<span className='nav-icon text-primary'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-users'
												>
													<path d='M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2' />
													<circle
														cx={9}
														cy={7}
														r={4}
													/>
													<path d='M23 21v-2a4 4 0 0 0-3-3.87' />
													<path d='M16 3.13a4 4 0 0 1 0 7.75' />
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Admin/Giáo viên
											</span>
										</NavLink>
									</li>
									<li>
										<NavLink
											activeStyle={{ color: "#448bff" }}
											to={`/settings`}
										>
											<span className='nav-icon text-primary'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-settings mx-2'
												>
													<circle
														cx={12}
														cy={12}
														r={3}
													/>
													<path d='M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z' />
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Cài đặt hệ thống
											</span>
										</NavLink>
									</li>
								</ul>
							) : (
								<ul className='nav bg'>
									<li className='nav-header hidden-folded'>
										<span className='text-muted'>
											Quản lý đào tạo
										</span>
									</li>
									<li>
										<NavLink
											activeStyle={{ color: "#448bff" }}
											to={`/exam`}
										>
											<span className='nav-icon text-primary'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='16'
													height='16'
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-package mx-2'
												>
													<path d='M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z'></path>
													<polyline points='2.32 6.16 12 11 21.68 6.16'></polyline>
													<line
														x1='12'
														y1='22.76'
														x2='12'
														y2='11'
													></line>
													<line
														x1='7'
														y1='3.5'
														x2='17'
														y2='8.5'
													></line>
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Kho đề thi
											</span>
										</NavLink>
									</li>

									<li>
										<NavLink
											activeStyle={{ color: "#448bff" }}
											to={`/question`}
										>
											<span className='nav-icon text-primary'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='16'
													height='16'
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-bookmark mx-2'
												>
													<path d='M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z'></path>
												</svg>
											</span>{" "}
											<span className='nav-text'>
												Kho câu hỏi
											</span>
										</NavLink>
									</li>
								</ul>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className='layout-column flex' style={{ background: "#fff" }}>
				<div id='header' className='page-header'>
					<div className='navbar navbar-expand-lg'>
						<a href='/' className='navbar-brand d-lg-none'>
							<svg
								width={32}
								height={32}
								viewBox='0 0 512 512'
								xmlns='http://www.w3.org/2000/svg'
								fill='currentColor'
							>
								<g
									className='loading-spin'
									style={{
										transformOrigin: "256px 256px",
									}}
								>
									<path d='M200.043 106.067c-40.631 15.171-73.434 46.382-90.717 85.933H256l-55.957-85.933zM412.797 288A160.723 160.723 0 0 0 416 256c0-36.624-12.314-70.367-33.016-97.334L311 288h101.797zM359.973 134.395C332.007 110.461 295.694 96 256 96c-7.966 0-15.794.591-23.448 1.715L310.852 224l49.121-89.605zM99.204 224A160.65 160.65 0 0 0 96 256c0 36.639 12.324 70.394 33.041 97.366L201 224H99.204zM311.959 405.932c40.631-15.171 73.433-46.382 90.715-85.932H256l55.959 85.932zM152.046 377.621C180.009 401.545 216.314 416 256 416c7.969 0 15.799-.592 23.456-1.716L201.164 288l-49.118 89.621z'></path>
								</g>
							</svg>{" "}
							<span className='hidden-folded d-inline l-s-n-1x d-lg-none'>
								LTTD
							</span>
						</a>
						<div
							className='collapse navbar-collapse order-2 order-lg-1'
							id='navbarToggler'
						></div>
						<ul className='nav navbar-menu order-1 order-lg-2'>
							<li className='nav-item dropdown'>
								<a
									href='/'
									data-toggle='dropdown'
									className='nav-link d-flex align-items-center px-2 text-color'
								>
									<span
										className='avatar w-24'
										style={{ margin: "-2px" }}
									>
										<span
											style={{
												width: "50px",
												height: "50px",
											}}
										></span>
										<img
											src={"https://cdn.luyenthitiendat.vn/assets/img/no-avatar.png"}
											alt='...'
											style={{
												width: "100%",
												height: "100%",
											}}
										/>
									</span>
								</a>
								<div className='dropdown-menu dropdown-menu-right w mt-3 animate fadeIn'>
									<a href='#' className='dropdown-item'>
										<span>
											{!isNull(user) &&
												!isUndefined(user.fullname)
												? user.fullname
												: "Admin"}
										</span>{" "}
									</a>
									<a href='#' className='dropdown-item'>
										<span className='badge bg-success text-uppercase'>
											Admin
										</span>
									</a>
									<div className='dropdown-divider' />

									<Link
										to={"/profile"}
										className='dropdown-item'
									>
										Thông tin cá nhân
									</Link>

									<div className='dropdown-divider' />

									<Link
										to={"/changepassword"}
										className='dropdown-item'
									>
										Đổi mật khẩu
									</Link>

									<a
										className='dropdown-item'
										onClick={(e) => handleLogout(e)}
									>
										Đăng xuất
									</a>
								</div>
							</li>
							<li className='nav-item d-lg-none'>
								<a
									href='/'
									className='nav-link px-2'
									data-toggle='collapse'
									data-toggle-class
									data-target='#navbarToggler'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width={16}
										height={16}
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										strokeWidth={2}
										strokeLinecap='round'
										strokeLinejoin='round'
										className='feather feather-search'
									>
										<circle cx={11} cy={11} r={8} />
										<line
											x1={21}
											y1={21}
											x2='16.65'
											y2='16.65'
										/>
									</svg>
								</a>
							</li>
							<li className='nav-item d-lg-none'>
								<a
									href='#'
									className='nav-link px-1'
									data-toggle='modal'
									data-target='#aside'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width={16}
										height={16}
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										strokeWidth={2}
										strokeLinecap='round'
										strokeLinejoin='round'
										className='feather feather-menu'
									>
										<line x1={3} y1={12} x2={21} y2={12} />
										<line x1={3} y1={6} x2={21} y2={6} />
										<line x1={3} y1={18} x2={21} y2={18} />
									</svg>
								</a>
							</li>
						</ul>
					</div>
				</div>

				<div className='flex'>
					<Switch>
						<PrivateRoute exact path={`/`} component={Home} />

						<PrivateRoute
							exact={true}
							path={`/review`}
							component={Review}
						/>
						<PrivateRoute
							exact={true}
							path={`/review/create`}
							component={ReviewCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/review/:id/edit`}
							component={ReviewEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/book`}
							component={Book}
						/>
						<PrivateRoute
							exact={true}
							path={`/book/create`}
							component={BookCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/book/:id/edit`}
							component={BookEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/book/review`}
							component={BookReview}
						/>
						<PrivateRoute
							exact={true}
							path={`/book/review/create`}
							component={BookReviewCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/book/:book_id/review`}
							component={BookReview}
						/>

						<PrivateRoute
							exact={true}
							path={`/book/review/:review_id/edit`}
							component={BookReviewEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/book-category`}
							component={BookCategory}
						/>
						<PrivateRoute
							exact={true}
							path={`/book-category/create`}
							component={BookCategoryCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/book-category/:id/edit`}
							component={BookCategoryEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/registration`}
							component={ListRegister}
						/>
						<PrivateRoute
							exact={true}
							path={`/registration/:id/edit`}
							component={RegisterEdit}
						/>
						<PrivateRoute
							exact={true}
							path={`/classroom-group`}
							component={ClassroomGroup}
						/>
						<PrivateRoute
							exact={true}
							path={`/classroom-group/create`}
							component={ClassroomGroupCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/classroom-group/:id/edit`}
							component={ClassroomGroupEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/classroom`}
							component={Classroom}
						/>
						<PrivateRoute
							exact={true}
							path={`/classroom/create`}
							component={ClassroomCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/classroom/:id/edit`}
							component={ClassroomEdit}
						/>
						<PrivateRoute
							exact={true}
							path={`/classroom/:id/code`}
							component={ClassroomCode}
						/>

						<PrivateRoute
							exact={true}
							path={`/classroom/:id/report`}
							component={ClassroomReport}
						/>

						<PrivateRoute
							exact={true}
							path={`/classroom/:id/member`}
							component={ClassroomMember}
						/>

						<PrivateRoute
							exact={true}
							path={`/student`}
							component={Student}
						/>
						<PrivateRoute
							exact={true}
							path={`/student/create`}
							component={StudentCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/student/:id/edit`}
							component={StudentEdit}
						/>
						<PrivateRoute
							exact={true}
							path={`/check-card`}
							component={CheckCard}
						/>
						<PrivateRoute
							exact={true}
							path={`/diligence`}
							component={Diligence}
						/>

						<PrivateRoute
							exact={true}
							path={`/admin`}
							component={Admin}
						/>
						<PrivateRoute
							exact={true}
							path={`/admin/create`}
							component={AdminCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/admin/:id/edit`}
							component={AdminEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/testing`}
							component={Testing}
						/>
						<PrivateRoute
							exact={true}
							path={`/testing/:id/edit`}
							component={TestingEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/exam`}
							component={Exam}
						/>
						<PrivateRoute
							exact={true}
							path={`/exam/create`}
							component={ExamCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/exam/:id/edit`}
							component={ExamEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/exam-category`}
							component={ExamCategory}
						/>
						<PrivateRoute
							exact={true}
							path={`/exam-category/create`}
							component={ExamCategoryCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/exam-category/:id/edit`}
							component={ExamCategoryEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/exam/:id/report`}
							component={ExamReport}
						/>

						<PrivateRoute
							exact={true}
							path={`/question`}
							component={Question}
						/>
						<PrivateRoute
							exact={true}
							path={`/question/create`}
							component={QuestionCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/question/:id/edit`}
							component={QuestionEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/changepassword`}
							component={Changepassword}
						/>
						<PrivateRoute
							exact={true}
							path={`/profile`}
							component={Profile}
						/>

						<PrivateRoute
							exact={true}
							path={`/subject`}
							component={Subject}
						/>
						<PrivateRoute
							exact={true}
							path={`/subject/create`}
							component={subjectCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/subject/:id/edit`}
							component={SubjectEdit}
						/>
						<PrivateRoute
							exact={true}
							path={`/chapter`}
							component={Chapter}
						/>
						<PrivateRoute
							exact={true}
							path={`/chapter/create`}
							component={ChapterCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/chapter/:id/edit`}
							component={chapterEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/category`}
							component={Category}
						/>
						<PrivateRoute
							exact={true}
							path={`/category/create`}
							component={CategoryCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/category/:id/edit`}
							component={CategoryEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/document`}
							component={Document}
						/>
						<PrivateRoute
							exact={true}
							path={`/document/create`}
							component={DocumentCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/document/:id/edit`}
							component={DocumentEdit}
						/>
						<PrivateRoute
							exact={true}
							path={`/settings`}
							component={Setting}
						/>
						<PrivateRoute
							exact={true}
							path={`/message`}
							component={Message}
						/>
						<PrivateRoute
							exact={true}
							path={`/message/create`}
							component={MessageCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/message/:id/edit`}
							component={MessageEdit}
						/>
						<PrivateRoute
							exact={true}
							path={`/schedule`}
							component={Schedule}
						/>

						<PrivateRoute
							exact={true}
							path={`/bill`}
							component={Bill}
						/>
						<PrivateRoute
							exact={true}
							path={`/bill/create`}
							component={BillCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/bill/:id/edit`}
							component={BillEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/bill-report`}
							component={BillReport}
						/>

						<PrivateRoute
							exact={true}
							path={`/home`}
							component={Home}
						/>

						<PrivateRoute
							exact={true}
							path={`/blog`}
							component={Blog}
						/>

						<PrivateRoute
							exact={true}
							path={`/adult-evaluation`}
							component={AdultEvaluation}
						/>
						<PrivateRoute
							exact={true}
							path={`/adult-evaluation/create`}
							component={AdultEvaluationCreate}
						/>
						<PrivateRoute
							exact={true}
							path={`/adult-evaluation/:id/edit`}
							component={AdultEvaluationEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/magazine`}
							component={Magazine}
						/>

						<PrivateRoute
							exact={true}
							path={`/magazine/create`}
							component={MagazineCreate}
						/>

						<PrivateRoute
							exact={true}
							path={`/magazine/:id/edit`}
							component={MagazineEdit}
						/>

						<PrivateRoute
							exact={true}
							path={`/credit`}
							component={Credit}
						/>

						<PrivateRoute
							exact={true}
							path={`/order`}
							component={Order}
						/>

						<PrivateRoute
							exact={true}
							path={`/order/:id/details`}
							component={OrderDetails}
						/>
						<PrivateRoute
							exact={true}
							path={`/report-bug`}
							component={ReportBug}
						/>
						<PrivateRoute
							exact={true}
							path={`/coupon`}
							component={Coupon}
						/>
					</Switch>
				</div>
				<div id='footer' className='page-footer'>
					<div className='d-flex p-3'>
						<span className='text-sm text-muted flex'>
							© Copyright. luyenthitiendat.vn
						</span>
						<div className='text-sm text-muted'>Version 1.0</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	token: state.token,
	user: state.auth.user,
	isAuthenticated: state.auth.isAuthenticated,
});

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ logout, loadUser }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Master));
