import React, { Component } from "react";
import Moment from "moment";
import { notification } from "antd";
import Pagination from "react-js-pagination";
import {
  listReportBug,
  deleteReportBug,
  addDelete,
  updateReportBug,
  checkAll,
} from "../../redux/bug/action";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Editor } from "@tinymce/tinymce-react";
class Row extends Component {
  constructor(props) {
    super();
    this.state = {
      check: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.check !== nextProps.check) {
      this.setState({
        check: nextProps.check,
      });
    }
  }

  handleCheck = (e) => {
    if (e.target.checked) {
      this.props.addDelete(this.props.obj._id, "add");
      this.setState({
        check: e.target.checked,
      });
    } else {
      this.props.addDelete(this.props.obj._id, "remove");
      this.setState({
        check: e.target.checked,
      });
    }
  };

  changeRowStatus = async (e) => {
    const id = e.target.attributes.getNamedItem("data-id").value;
    const status = e.target.value;
    await this.props.updateReportBug({ id, status });
  };

  render() {
    const {
      subject,
      user,
      status,
      classroom,
      content,
      object_type,
      object_id,
      _id,
      code,
    } = this.props.obj;
    return (
      <tr className="v-middle" data-id={17}>
        <td>
          <label className="ui-check m-0">
            <input
              type="checkbox"
              name="id"
              onChange={this.handleCheck}
              checked={this.state.check === true ? "checked" : ""}
            />{" "}
            <i />
          </label>
        </td>
        <td className="flex">
          <Link
            onClick={(e) => this.props.showBugDetail(this.props.obj)}
            className="item-author text-color"
            data-toggle="modal"
            data-target="#show-bug-modal"
            data-toggle-class="fade-down"
            data-toggle-class-target=".animate"
          >
            {code}
          </Link>
        </td>
        <td>
          <span className="item-amount d-none d-sm-block text-sm">
            {object_type}
          </span>
        </td>
        <td>
          <span className="item-amount d-none d-sm-block text-sm">
            {user.name}
          </span>
        </td>
        <td>
          <select
            name="status"
            className="custom-select"
            data-id={this.props.obj._id}
            onChange={this.changeRowStatus}
          >
            <option value="PENDING">Chờ xử lý</option>
            <option value="DONE">Đã xử lý</option>
          </select>
        </td>
        <td>
          <span className="item-amount d-none d-sm-block text-sm">
            {this.props.obj.updated_at &&
              Moment(this.props.obj.updated_at).format("DD/MM/YYYY HH:mm:ss")}
          </span>
        </td>
        <td>
          <div className="item-action dropdown">
            <a href="/" data-toggle="dropdown" className="text-muted">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-more-vertical"
              >
                <circle cx={12} cy={12} r={1} />
                <circle cx={12} cy={5} r={1} />
                <circle cx={12} cy={19} r={1} />
              </svg>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right bg-white"
              role="menu"
            >
              <Link
                onClick={(e) => this.props.showBugDetail(this.props.obj)}
                className="dropdown-item trash"
                data-toggle="modal"
                data-target="#show-bug-modal"
                data-toggle-class="fade-down"
                data-toggle-class-target=".animate"
              >
                Chi tiết
              </Link>

              <div className="dropdown-divider" />
              <button
                onClick={(e) => this.props.addDelete(this.props.obj._id)}
                className="dropdown-item trash"
                data-toggle="modal"
                data-target="#delete-video"
                data-toggle-class="fade-down"
                data-toggle-class-target=".animate"
              >
                Xóa
              </button>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

class Bug extends Component {
  constructor(props) {
    super();
    this.state = {
      data: [],
      limit: "",
      status: "PENDING",
      checkAll: false,
      bugContent: "",
    };
  }

  fetchRows() {
    if (this.props.bugs instanceof Array) {
      return this.props.bugs.map((object, i) => {
        return (
          <Row
            obj={object}
            key={object._id}
            index={i}
            addDelete={this.props.addDelete}
            updateReportBug={this.props.updateReportBug}
            listChapter={this.props.listChapter}
            getData={this.getData}
            showBugDetail={this.showBugDetail}
            check={this.props.check}
          />
        );
      });
    }
  }

  showBugDetail = (bug) => {
    if (bug) {
      this.setState({
        bugContent: bug.content,
      });
    }
  };

  onChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  getData = (pageNumber = 1) => {
    const data = {
      page: pageNumber,
      limit: this.props.limit,
      status: this.state.status,
      is_delete: false,
    };
    if (this.state.keyword != null) {
      data["keyword"] = this.state.keyword;
    }
    return data;
  };

  async componentDidMount() {
    const data = {
      limit: 999,
      is_delete: false,
    };
    await this.props.listReportBug(this.getData());
    if (this.props.limit) {
      await this.setState({
        limit: this.props.limit,
        checkAll: false,
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.listReportBug(this.getData());
  };

  handleChangePage = async (pageNumber) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    await this.props.listReportBug(this.getData(pageNumber));
  };

  handleDelete = async () => {
    const data = {
      ids: this.props.ids,
    };
    if (data.ids.length !== 0) {
      await this.props.deleteReportBug(data);
      await this.props.listReportBug(this.getData());
    } else {
      notification.warning({
        message: "Chưa chọn mục nào !",
        placement: "topRight",
        top: 50,
        duration: 3,
      });
    }
  };

  handleChange = async (e) => {
    var name = e.target.name;
    var value = e.target.value;
    await this.setState({
      [name]: value,
    });
    await this.props.listReportBug(this.getData());
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.checkAll !== nextProps.check) {
      this.setState({
        checkAll: nextProps.check,
      });
    }
  }

  handleCheckAll = (e) => {
    if (e.target.checked) {
      this.props.checkAll(true);
      this.setState({
        checkAll: e.target.checked,
      });
    } else {
      this.props.checkAll(false);
      this.setState({
        checkAll: e.target.checked,
      });
    }
  };

  render() {
    let displayFrom =
      this.props.page === 1
        ? 1
        : (parseInt(this.props.page) - 1) * this.props.limit;
    let displayTo =
      this.props.page === 1 ? this.props.limit : displayFrom + this.props.limit;
    displayTo = displayTo > this.props.total ? this.props.total : displayTo;
    return (
      <div>
        <div className="page-hero page-container" id="page-hero">
          <div className="padding d-flex">
            <div className="page-title">
              <h2 className="text-md text-highlight">Báo lỗi</h2>
              <small className="text-muted">Quản lý lỗi</small>
            </div>
            <div className="flex" />
            <div></div>
          </div>
        </div>
        <div className="page-content page-container" id="page-content">
          <div className="padding">
            <div className="mb-5">
              <div className="toolbar">
                <div className="btn-group">
                  {this.props.ids.length !== 0 ? (
                    <button
                      className="btn btn-icon"
                      data-toggle="modal"
                      data-target="#delete-video"
                      data-toggle-class="fade-down"
                      data-toggle-class-target=".animate"
                      title="Trash"
                      id="btn-trash"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trash text-muted"
                      >
                        <polyline points="3 6 5 6 21 6" />
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                      </svg>
                    </button>
                  ) : (
                    <button
                      className="btn btn-icon"
                      onClick={this.handleDelete}
                      title="Trash"
                      id="btn-trash"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trash text-muted"
                      >
                        <polyline points="3 6 5 6 21 6" />
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                      </svg>
                    </button>
                  )}
                </div>
                <form className="flex" onSubmit={this.onSubmit}>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control form-control-theme keyword-custom"
                      placeholder="Nhập từ khoá tìm kiếm..."
                      onChange={this.onChange}
                      name="keyword"
                    />{" "}
                    <span className="input-group-append">
                      <button className="btn btn-white btn-sm" type="submit">
                        <span className="d-flex text-muted">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-search"
                          >
                            <circle cx={11} cy={11} r={8} />
                            <line x1={21} y1={21} x2="16.65" y2="16.65" />
                          </svg>
                        </span>
                      </button>
                    </span>
                    <select
                      className="custom-select mr-2"
                      value={this.state.status}
                      name="status"
                      onChange={this.handleChange}
                    >
                      <option value="">-- Chọn trạng thái --</option>
                      <option value="PENDING">Chờ xử lý</option>
                      <option value="PROCESSING">Đang xử lý</option>
                      <option value="DONE">Đã xử lý</option>
                    </select>
                  </div>
                </form>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <table className="table table-theme table-row v-middle">
                    <thead className="text-muted">
                      <tr>
                        <th width="10px">
                          <label className="ui-check m-0">
                            <input
                              type="checkbox"
                              name="id"
                              onChange={this.handleCheckAll}
                              checked={
                                this.state.checkAll === true ? "checked" : ""
                              }
                            />{" "}
                            <i />
                          </label>
                        </th>
                        <th>Mã lỗi</th>
                        <th>Nhóm lỗi</th>
                        <th>Người báo lỗi</th>
                        <th>Tình trạng</th>
                        <th width="">Thời gian cập nhật</th>
                        <th width="50px" />
                      </tr>
                    </thead>
                    <tbody>{this.fetchRows()}</tbody>
                  </table>
                </div>
              </div>

              <div className="row listing-footer">
                <div className="col-sm-2">
                  <select
                    className="custom-select w-96"
                    name="limit"
                    value={this.state.limit}
                    onChange={this.handleChange}
                  >
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="-1">ALL</option>
                  </select>
                </div>
                <div className="col-sm-5 showing-text">
                  {" "}
                  Hiển thị từ <b>
                    {!isNaN(displayFrom) ? displayFrom : 0}
                  </b> đến <b>{!isNaN(displayTo) ? displayTo : 0}</b> trong tổng
                  số <b>{this.props.total}</b>
                </div>
                {this.props.total !== 0 ? (
                  <div className="col-sm-5 text-right">
                    <Pagination
                      activePage={this.props.page}
                      itemsCountPerPage={this.props.limit}
                      totalItemsCount={this.props.total}
                      pageRangeDisplayed={10}
                      onChange={this.handleChangePage}
                    />
                  </div>
                ) : (
                  <div className="">Không có bản ghi nào</div>
                )}
              </div>

              <div
                id="show-bug-modal"
                className="modal fade"
                data-backdrop="true"
                style={{ display: "none" }}
                aria-hidden="true"
              >
                <div
                  className="modal-dialog animate fade-down"
                  data-class="fade-down"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title text-md">
                        Chi tiết báo lỗi
                      </div>
                      <button className="close" data-dismiss="modal">
                        ×
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="text-center">
                        <Editor
                          value={this.state.bugContent}
                          init={{
                            height: 400,
                            menubar: false,
                            images_file_types:
                              "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                            ],
                            external_plugins: {
                              tiny_mce_wiris:
                                "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                            },
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | image | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                              "searchreplace visualblocks code fullscreen | " +
                              "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                            fontsize_formats:
                              "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            draggable_modal: true,
                            htmlAllowedTags: [".*"],
                            htmlAllowedAttrs: [".*"],
                          }}
                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light"
                        data-dismiss="modal"
                      >
                        Đóng
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="delete-video"
                className="modal fade"
                data-backdrop="true"
                style={{ display: "none" }}
                aria-hidden="true"
              >
                <div
                  className="modal-dialog animate fade-down"
                  data-class="fade-down"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title text-md">Thông báo</div>
                      <button className="close" data-dismiss="modal">
                        ×
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="p-4 text-center">
                        <p>Bạn chắc chắn muốn xóa bản ghi này chứ?</p>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light"
                        data-dismiss="modal"
                      >
                        Đóng
                      </button>
                      <button
                        type="button"
                        onClick={this.handleDelete}
                        className="btn btn-danger"
                        data-dismiss="modal"
                      >
                        Xoá
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bugs: state.reportBug.reportBugs,
    limit: state.reportBug.limit,
    page: state.reportBug.page,
    total: state.reportBug.total,
    ids: state.reportBug.ids,
    check: state.reportBug.checkAll,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { listReportBug, deleteReportBug, addDelete, checkAll, updateReportBug },
    dispatch
  );
}

let BugContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Bug)
);
export default BugContainer;
