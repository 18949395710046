import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { listClassroom } from '../../redux/classroom/action';
import { createReview } from '../../redux/review/action';

import { Select } from "antd";

class ReviewCreate extends Component {
    constructor(props) {
        super();
        this.state = {
            name: '',
            classroom_id: '',
            classroom_name: '',
            status: true,
            avatar_base64: "",
            rating: 1,
            comment: ''
        };
    }

    async componentDidMount() {
        console.log('Todo');
    }

    _onChange = async e => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "avatar_base64") {
            value = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = (error) => reject(error);
            });
            value = value;
        }
        this.setState({
            [name]: value,
        });
    };

    _onChangeClassroom = async (value) => {
        await this.setState({
            classroom_id: value,
        });
    };

    _onSearchClassroom = async (value) => {
        if (value) {
            await this.props.listClassroom({ limit: 999, keyword: value });
        }
    }

    handleSubmit = async (type) => {
        const data = {
            name: this.state.name,
            classroom_id: this.state.classroom_id,
            comment: this.state.comment,
            status: this.state.status,
            rating: this.state.rating,
            avatar_base64: this.state.avatar_base64,
        };

        if (type === 0) {
            await this.props.createReview(data);
            if (this.props.redirect === true) {
                await this.props.history.push('/review');
            }
        } else {

            await this.props.createReview(data);
        }


    };


    fetchClassroomRows() {
        if (this.props.classrooms instanceof Array) {
            return this.props.classrooms.map((obj, i) => {
                return <option value={obj._id}>{obj.name}</option>;
            });
        }
    }

    render() {
        return (
            <div>
                <div className="page-hero page-container" id="page-hero">
                    <div className="padding d-flex">
                        <div className="page-title">
                            <h2 className="text-md text-highlight">Thêm mới đánh giá</h2>
                        </div>
                        <div className="flex" />
                        <div>
                            <Link
                                to={'/classroom-group'}
                                className="btn btn-sm text-white btn-primary">
                                <span className="d-none d-sm-inline mx-1">
                                    Quay lại
                                </span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-right">
                                    <line x1={5} y1={12} x2={19} y2={12} />
                                    <polyline points="12 5 19 12 12 19" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="page-content page-container" id="page-content">
                    <div className="padding">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="card">
                                    <div className="card-header">
                                        <strong>Thêm  mới đánh giá</strong>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <div className="col-sm-6">
                                                <label className="col-form-label">
                                                    Tên
                                                </label>
                                                <div >
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        onChange={this._onChange}
                                                        value={this.state.name}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <label className=" col-form-label">
                                                    Lớp
                                                </label>
                                                <div className="">
                                                    <Select
                                                        showSearch
                                                        style={{ width: "100%" }}
                                                        placeholder="Tìm và chọn lớp"
                                                        value={this.state.classroom_id}
                                                        optionFilterProp="children"
                                                        onChange={this._onChangeClassroom}
                                                        onSearch={this._onSearchClassroom}
                                                        filterOption={(input, option) =>
                                                            option.props.children
                                                                .toLowerCase()
                                                                .indexOf(
                                                                    input.toLowerCase()
                                                                ) >= 0
                                                        }
                                                    >
                                                        {this.fetchClassroomRows()}
                                                    </Select>
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <label className="col-form-label">
                                                    Số sao
                                                </label>
                                                <div >
                                                    <select className="form-control" name="rating">
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                        <option value={3}>3</option>
                                                        <option value={4}>4</option>
                                                        <option value={5}>5</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <label className="col-form-label">
                                                    Avatar
                                                </label>
                                                <input
                                                    onChange={this._onChange}
                                                    type="file"
                                                    className="form-control-file"
                                                    name="avatar_base64"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <label className=" col-form-label">
                                                    Nội dung đánh giá
                                                </label>
                                                <div>
                                                    <textarea
                                                        className="form-control"
                                                        name="comment"
                                                        onChange={this._onChange}
                                                        value={this.state.comment}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <label className=" col-form-label">
                                                    Trạng thái
                                                </label>
                                                <div>
                                                    <div className="form-check float-left">
                                                        <input className="form-check-input" type="radio" name="status" value="true" id="gridRadios1" onChange={this._onChange} defaultValue="option1" defaultChecked />
                                                        <label className="form-check-label" htmlFor="gridRadios1">
                                                            Hiển thị
                                                        </label>
                                                    </div>
                                                    <div className="form-check float-left ml-4">
                                                        <input className="form-check-input" type="radio" name="status" value="false" id="gridRadios2" onChange={this._onChange} defaultValue="option2" />
                                                        <label className="form-check-label" htmlFor="gridRadios2">
                                                            Ẩn
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12 text-right">
                                                <button
                                                    className="btn btn-primary mt-2"
                                                    onClick={() => this.handleSubmit(0)}>
                                                    Lưu
                                                </button>
                                                <button
                                                    className="btn btn-primary mt-2 ml-2"
                                                    onClick={() => this.handleSubmit(1)}>
                                                    Lưu & Thêm mới
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        classrooms: state.classroom.classrooms,
        redirect: true,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ listClassroom, createReview }, dispatch);
}

let ContainerCreate = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ReviewCreate),
);

export default ContainerCreate;
