import React, { Component } from "react";

class Home extends Component {
	render() {
		return (
			<div>
				<div className='page-content page-container' id='page-content'>
					<div className='padding' style={{ display: 'none' }}>
						<div className='row'>
							<div className='col-md-12'>
								<div className='card'>
									<h5 className='mt-3 mb-3'>
										Chuyên đề sắp khai giảng
									</h5>
									<table className='table table-bordered mb-0'>
										<thead>
											<tr>
												<th scope='col'>Môn</th>
												<th scope='col'>Chuyên đề</th>
												<th scope='col'>
													Ngày khai giảng
												</th>
												<th scope='col'>Lịch học</th>
												<th scope='col'>Giáo viên</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Toán</td>
												<td>Mũ và Logarit</td>
												<td>15/5/2020</td>
												<td>19:30 T3 và T5</td>
												<td>Thầy Đạt</td>
											</tr>
											<tr>
												<td>Toán</td>
												<td>Mũ và Logarit</td>
												<td>15/5/2020</td>
												<td>19:30 T3 và T5</td>
												<td>Thầy Đạt</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							<div className='col-md-12'>
								<div className='card'>
									<h5 className='mt-3 mb-3'>
										Học sinh sinh nhật hôm nay
									</h5>
									<table className='table table-bordered mb-0'>
										<thead>
											<tr>
												<th scope='col'>Mã học sinh</th>
												<th scope='col'>
													Tên Học sinh
												</th>
												<th scope='col'>
													Lớp đang theo học
												</th>
												<th scope='col'>
													Ngày tham gia vào trung tâm
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>A1232124</td>
												<td>Nguyễn Văn A</td>
												<td>Toán 12B, Văn 12V2</td>
												<td>11/2/2019</td>
											</tr>
											<tr>
												<td>A1232124</td>
												<td>Nguyễn Văn A</td>
												<td>Toán 12B, Văn 12V2</td>
												<td>11/2/2019</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Home;
