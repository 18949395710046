import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showMagazine, updateMagazine } from '../../redux/magazine/action';
import { listAdmin } from "../../redux/student/action";
import { Editor } from "@tinymce/tinymce-react";



class MagazineEdit extends Component {
    constructor(props) {
        super();
        this.state = {
            name: '',
            description: '',
            files: [],
            external_link: '',
            status: true,
            teacher_id: null,
            content: ""
            // checkedTrue: null,
            // checkedFlase: null,
        };
    }

    async componentDidMount() {
        await this.props.showMagazine(this.props.match.params.id);

        const params = {
            user_group: "TEACHER",
            limit: 100,
        };
        await this.props.listAdmin(params);

        if (this.props.magazine) {
            let { name, description, files, external_link, status, teacher_id, content } = this.props.magazine;
            this.setState({
                name,
                description,
                files,
                external_link,
                status,
                content,
                teacher_id: teacher_id ? teacher_id : null
            })
        }
    }

    _onChange = async e => {
        var name = e.target.name;
        let value = e.target.value;
        if (name === 'files') {
            value = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = () => {
                    resolve(reader.result);
                }
                reader.onerror = error => reject(error);
            });
            value = [value];
        }
        await this.setState({
            [name]: value,
        });
    };

    handleSubmit = e => {
        e.preventDefault();

        const data = {
            id: this.props.match.params.id,
            name: this.state.name,
            description: this.state.description,
            external_link: this.state.external_link,
            files: this.state.files,
            status: this.state.status,
            teacher_id: this.state.teacher_id,
            content: this.state.content
        };
        this.props.updateMagazine(data);
        // if (this.props.redirect === true) {
        //     this.props.history.push('/magazine');
        // }
    };

    fetchTeacherRows() {
        if (this.props.students instanceof Array) {
            return this.props.students.map((obj, i) => {
                return (
                    <option value={obj._id} key={obj._id.toString()}>
                        {obj.fullname}
                    </option>
                );
            });
        }
    }

    fetchRows() {

    }

    _handleEditorDescriptionChange = (value, editor) => {
        this.setState({ description: value });
    };
    _handleEditorContentChange = (value, editor) => {
        this.setState({ content: value });
    };

    render() {
        return (
            <div>
                <div className="page-hero page-container" id="page-hero">
                    <div className="padding d-flex">
                        <div className="page-title">
                            <h2 className="text-md text-highlight">Cập nội dung tiêu chí bài viết</h2>
                        </div>
                        <div className="flex" />
                        <div>
                            <Link
                                to={'/magazine'}
                                className="btn btn-sm text-white btn-primary">
                                <span className="d-none d-sm-inline mx-1">
                                    Quay lại
                                </span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-right">
                                    <line x1={5} y1={12} x2={19} y2={12} />
                                    <polyline points="12 5 19 12 12 19" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="page-content page-container" id="page-content">
                    <div className="padding">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="card">
                                    <div className="card-header">
                                        <strong>Cập nhật đánh giá</strong>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">
                                                    Tên
                                                </label>
                                                <div className='col-sm-8'>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        onChange={this._onChange}
                                                        value={this.state.name}
                                                    />
                                                </div>
                                            </div>
                                        <div className="form-group row">

                                                <label className="col-sm-4 col-form-label">Chọn giáo viên</label>
                                                <div className='col-sm-8'>
                                                    <select
                                                        className="custom-select"
                                                        value={this.state.teacher_id}
                                                        name="teacher_id"
                                                        onChange={this._onChange}
                                                    >
                                                        <option value="">-- Chọn giáo viên --</option>
                                                        {this.fetchTeacherRows()}
                                                    </select>
                                                </div>
                                        </div>
                                        <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">
                                                    Hình ảnh
                                                </label>
                                                <div className="col-sm-4">
                                                    <input type="file" onChange={this._onChange} className="form-control-file" name="files" />
                                                </div>
                                                <div className="col-sm-4 d-flex">
                                                    <img alt="" src={
                                                        this.props.magazine
                                                            ?
                                                            this.props.magazine.image
                                                            : ''
                                                    }
                                                        style={{ width: '200px' }} />
                                                </div>
                                            </div>
                                        <div className="form-group row">

                                                <label className="col-sm-4 col-form-label">
                                                    External_link
                                                </label>
                                                <div className="col-sm-8">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="external_link"
                                                        onChange={this._onChange}
                                                        value={this.state.external_link}
                                                    />
                                                </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">
                                                Mô tả ngắn
                                            </label>
                                            <div className="col-sm-8">
                                                <Editor
                                                    onInit={(evt, editor) => {
                                                        this._handleEditorDescriptionChange(
                                                            this.state.description,
                                                            editor
                                                        );
                                                    }}
                                                    value={this.state.description}
                                                    init={{
                                                        height: 400,
                                                        menubar: false,
                                                        images_file_types:
                                                            "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                                                        plugins: [
                                                            "advlist autolink lists link image charmap print preview anchor",
                                                            "searchreplace visualblocks code fullscreen",
                                                            "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                                                        ],
                                                        external_plugins: {
                                                            tiny_mce_wiris:
                                                                "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                        },
                                                        toolbar:
                                                            "undo redo | formatselect | " +
                                                            "bold italic backcolor | image | alignleft aligncenter " +
                                                            "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                                                            "searchreplace visualblocks code fullscreen | " +
                                                            "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                                                        fontsize_formats:
                                                            "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                                                        content_style:
                                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                        draggable_modal: true,
                                                        htmlAllowedTags: [".*"],
                                                        htmlAllowedAttrs: [".*"],
                                                        images_upload_handler: (
                                                            blobInfo,
                                                            success,
                                                            failure
                                                        ) =>
                                                            this._uploadImageCallBack(
                                                                blobInfo,
                                                                success,
                                                                failure
                                                            ),
                                                    }}
                                                    onEditorChange={this._handleEditorDescriptionChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">
                                                Nội dung
                                            </label>
                                            <div className="col-sm-8">
                                                <Editor
                                                    onInit={(evt, editor) => {
                                                        this._handleEditorContentChange(
                                                            this.state.content,
                                                            editor
                                                        );
                                                    }}
                                                    value={this.state.content}
                                                    init={{
                                                        height: 400,
                                                        menubar: false,
                                                        images_file_types:
                                                            "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                                                        plugins: [
                                                            "advlist autolink lists link image charmap print preview anchor",
                                                            "searchreplace visualblocks code fullscreen",
                                                            "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                                                        ],
                                                        external_plugins: {
                                                            tiny_mce_wiris:
                                                                "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                        },
                                                        toolbar:
                                                            "undo redo | formatselect | " +
                                                            "bold italic backcolor | image | alignleft aligncenter " +
                                                            "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                                                            "searchreplace visualblocks code fullscreen | " +
                                                            "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                                                        fontsize_formats:
                                                            "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                                                        content_style:
                                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                        draggable_modal: true,
                                                        htmlAllowedTags: [".*"],
                                                        htmlAllowedAttrs: [".*"],
                                                        images_upload_handler: (
                                                            blobInfo,
                                                            success,
                                                            failure
                                                        ) =>
                                                            this._uploadImageCallBack(
                                                                blobInfo,
                                                                success,
                                                                failure
                                                            ),
                                                    }}
                                                    onEditorChange={this._handleEditorContentChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <label className=" col-form-label">
                                                    Trạng thái
                                                </label>
                                                <div>
                                                    <div className="form-check float-left">
                                                        <input checked={this.state.status === true || this.state.status === "true"} className="form-check-input"
                                                            type="radio" name="status" value="true" id="gridRadios1"
                                                            onChange={this._onChange} defaultValue="option1" />
                                                        <label className="form-check-label" htmlFor="gridRadios1">
                                                            Hiển thị
                                                        </label>
                                                    </div>
                                                    <div className="form-check float-left ml-4">
                                                        <input checked={this.state.status === false || this.state.status === "false"}
                                                            className="form-check-input" type="radio" name="status" value="false" id="gridRadios2" onChange={this._onChange} defaultValue="option2" />
                                                        <label className="form-check-label" htmlFor="gridRadios2">
                                                            Ẩn
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12 text-right">
                                                <button
                                                    className="btn btn-primary mt-2"
                                                    onClick={this.handleSubmit}>
                                                    Cập nhật
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        magazine: state.magazine.magazine,
        redirect: true,
        students: state.student.students,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMagazine,
        updateMagazine,
        listAdmin
    }, dispatch);
}

let ContainerEdit = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MagazineEdit),
);

export default ContainerEdit;
